import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Skeleton,
  Checkbox,
  Grid,
} from "@mui/material";

const CustomTableHead = (props) => {
  const { column } = props;

  return (
    <TableHead>
      {props.rowSelection ? <TableCell></TableCell> : null}
      {column.map((item, index) => {
        return <TableCell key={index}>{item.title}</TableCell>;
      })}
    </TableHead>
  );
};

const DynamicTable = ({
  column = [],
  data = [],
  loading = false,
  rowSelection = false,
  paginationDisplay = true,
  onChange,
  tableType,
}) => {
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  useEffect(() => {
    setRowsPerPage(paginationDisplay ? 5 : data.length);
  }, []);
  useEffect(() => {
    setSelected([]);
    setRowsPerPage(paginationDisplay ? 5 : data.length);
  }, [data]);

  const resetSelection = () => {
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClick = (event, item) => {
    const selectedIndex = selected
      .map((item, index) => item.name)
      .indexOf(item.name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, item);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
    onChange("rowData", newSelected);
  };

  const isSelected = (name) =>
    selected.map((item, index) => item.name).indexOf(name) !== -1;

  const convertDate = (dateValue) => {
    if (dateValue) {
      let dateObject = new Date(dateValue);
      // 7/31/2020
      let todayYear = dateObject.getFullYear();

      let todayMonth = () => {
        let currentMonth = (dateObject.getMonth() + 1).toString();
        if (currentMonth.length < 2) {
          return "0" + currentMonth;
        } else {
          return currentMonth;
        }
      };
      let todayDay = () => {
        if (dateObject.getDate().toString().length < 2) {
          return "0" + dateObject.getDate().toString();
        } else {
          return dateObject.getDate().toString();
        }
      };
      let stringDate =
        todayYear.toString() + "-" + todayMonth() + "-" + todayDay();
      return stringDate;
    } else {
      return "";
    }
  };

  return (
    <Grid container md={12}>
      <TableContainer>
        <Table aria-label="simple table">
          <CustomTableHead
            rowCount={data.length}
            column={column}
            rowSelection={rowSelection}
          />

          {loading ? (
            <TableBody>
              {new Array(5).fill(0).map((item, index) => (
                <TableRow>
                  {column.map((heading, index) => (
                    <TableCell>
                      <Skeleton />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <TableBody>
              {data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => {
                  const isItemSelected = isSelected(item.name);

                  return (
                    <TableRow
                      key={item.id}
                      onClick={(event) => {
                        if (rowSelection) {
                          handleClick(event, item);
                        }
                      }}
                      role={rowSelection ? "checkbox" : null}
                      selected={rowSelection ? isItemSelected : null}
                      col
                    >
                      {rowSelection ? (
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          defaultChecked={false}
                        />
                      ) : null}

                      {column.map((heading, index) => {
                        const { type, field, money } = heading;
                        if (tableType === "journalView") {
                          if (type && type === "date") {
                            return (
                              <TableCell>{convertDate(item[field])}</TableCell>
                            );
                          } else if (
                            money &&
                            money === "debit" &&
                            item.type === "debit"
                          ) {
                            console.log(item[field]);
                            return <TableCell>{item.amount}</TableCell>;
                          } else if (
                            money &&
                            money === "credit" &&
                            item.type === "credit"
                          ) {
                            return <TableCell>{item.amount}</TableCell>;
                          } else if (money) {
                            return <TableCell> - </TableCell>;
                          } else {
                            return <TableCell>{item[field]}</TableCell>;
                          }
                        } else {
                          if (type && type === "date") {
                            return (
                              <TableCell>{convertDate(item[field])}</TableCell>
                            );
                          } else {
                            return <TableCell>{item[field]}</TableCell>;
                          }
                        }
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {paginationDisplay ? (
        <TablePagination
          rowsPerPageOptions={[5, 10, 30, 50]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      ) : null}
    </Grid>
  );
};

export default DynamicTable;
