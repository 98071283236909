// External Components
import React from "react";
import { Grid } from "@mui/material";
import HeadingAndSidebar from "./HeadingandSidebar";
import Footer from "../Footer";

const LandingComponent = () => {
  return (
    <>
      <Grid
        container
        xs={12}
        direction="column"
        // spacing={2}
        sx={{ backgroundColor: "#F4F5F7" }}
      >
        <Grid item xs={12}>
          <HeadingAndSidebar />
        </Grid>
        <Grid item xs={12}>
          <Footer />
        </Grid>
      </Grid>
    </>
  );
};

export default LandingComponent;
