// External Dependencies
import React, { useState, useEffect, useContext } from "react";

// @MUI Import
import { Grid, Typography } from "@mui/material/";

// Internal Dependencies
import { LedgerServices } from "../../../authentication/services/amtrix.books.services";
import { AlertContext } from "../../../context/AlertContext";
import LedgerSearch from "./components/LedgerSearch";
import LedgerView from "./components/LedgerView";
import { PageTittles } from "../../sharedComponents/pageTitle";

const ledgerServices = new LedgerServices();

const AccountingLedger = () => {
  const { changeAlertState } = useContext(AlertContext);
  const [ledgerList, setLedgerList] = useState([]);
  const [searchData, setSearchData] = useState({});

  const fetchLedgerEntries = async (searchObject) => {
    try {
      const response = await ledgerServices.getAllLedgerBetween(
        searchObject.accountName,
        searchObject.startDate,
        searchObject.endDate
      );
      console.log(response);
      if (response.status === 200) {
        setLedgerList(response.data.object.ledgerParticularDtos);
        setSearchData(searchObject);
      } else if (response.status === 404) {
        changeAlertState({
          open: true,
          message: "No entries found",
          severity: "info",
        });
      } else {
        changeAlertState({
          open: true,
          message: response.message,
          severity: "error",
        });
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message &&
        error.response.data.message === "Ledger not found"
      ) {
        setLedgerList([]);
        changeAlertState({
          open: true,
          message: "Ledger not found",
          severity: "info",
        });
      } else {
        changeAlertState({
          open: true,
          message: error.message,
          severity: "error",
        });
      }
    }
  };

  return (
    <>
        <Grid item xs={12}>
          <PageTittles
            headingTitle="Account View "
            subtitleText={`Sub Title`}
          />
        </Grid>
          <LedgerSearch fetchLedgerEntries={fetchLedgerEntries} />
          <LedgerView ledgerList={ledgerList} searchData={searchData} />
    </>
  );
};

export default AccountingLedger;
