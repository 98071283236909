// External Dependencies
import axios from "axios";

// Internal Dependencies
import { authHeader, authToken, authHeaderMultiPart } from "./auth-header.js";

// Constants
const API_URL = process.env.REACT_APP_AMTRIX_API_DEVELOPMENT;
const API_URL_BASE = process.env.REACT_APP_AMTRIX_API_DEVELOPMENT_BASE;

class AccountHeadServices {
  saveAccountHead = async newAccountHead => {
    const response = await axios.post(`${API_URL}/accountHead`, newAccountHead, {
      headers: authHeader()
    });
    return response;
  };

  getAllAccountHeads = async () => {
    const response = await axios.get(`${API_URL}/accountHead/findAll`, {
      headers: authHeader()
    });
    return response;
  };

  deleteAccountHead = async accountHeadData => {
    const response = await axios.delete(`${API_URL}/accountHead`, {
      headers: authHeader()
    });
    return response;
  };

  deleteAccountHeadById = async accountHeadId => {
    const response = await axios.delete(`${API_URL}/accountHead/delete/${accountHeadId}`, {
      headers: authHeader()
    });
    return response;
  };

  getAccountHeadLike = async (searchString, companyId) => {
    const response = await axios.get(
      `${API_URL}/accountHead/like/${searchString}?companyId=${companyId}`,
      {
        headers: authHeader()
      }
    );
    return response;
  };

  updateAccountHead = async accountHeadData => {
    const response = await axios.put(`${API_URL}/accountHead/`, accountHeadData, {
      headers: authHeader()
    });
    return response;
  };

  saveAccountHeadInformation = async newAccountHeadInformation => {
    const response = await axios.post(`${API_URL}/accountHeadDetail`, newAccountHeadInformation, {
      headers: authHeader()
    });
    return response;
  };

  updateAccountHeadInformation = async accountHeadInformationData => {
    const response = await axios.put(`${API_URL}/accountHeadDetail`, accountHeadInformationData, {
      headers: authHeader()
    });
    return response;
  };

  getByAccountHeadId = async accountHeadId => {
    const response = await axios.get(`${API_URL}/accountHeadDetail/${accountHeadId}`, {
      headers: authHeader()
    });
    return response;
  };
}

class AccountSubCategoryServices {
  deleteAccountSubCategory = async accountSubCategoryData => {
    const response = await axios.delete(`${API_URL}/accountSubCategory`, {
      headers: authHeader()
    });
    return response;
  };

  deleteAccountSubCategoryById = async accountSubCategoryId => {
    const response = await axios.delete(
      `${API_URL}/accountSubCategory/delete/${accountSubCategoryId}`,
      {
        headers: authHeader()
      }
    );
    return response;
  };

  getAccountSubCategoryById = async accountSubCategoryId => {
    const response = await axios.get(`${API_URL}/accountSubCategory/find/${accountSubCategoryId}`, {
      headers: authHeader()
    });
    return response;
  };

  getAllAccountSubCategory = async () => {
    const response = await axios.get(`${API_URL}/accountSubCategory/findAll`, {
      headers: authHeader()
    });
    return response;
  };

  getAccountSubCategoryLike = async (searchString, companyId) => {
    const response = await axios.get(
      `${API_URL}/accountSubCategory/like/${companyId}?companyId=${companyId}`,
      {
        headers: authHeader()
      }
    );
    return response;
  };
}

class BankServices {
  addNewBank = async newBank => {
    const response = await axios.post(`${API_URL}/bank`, newBank, {
      headers: authHeader()
    });
    return response;
  };

  getAllBanks = async () => {
    const response = await axios.get(`${API_URL}/bank/findAll`, {
      headers: authHeader()
    });
    return response;
  };
}

class BusinessActivityServices {
  addBusinessActivity = async newBusinessActivity => {
    const response = await axios.post(`${API_URL}/businessActivity`, newBusinessActivity, {
      headers: authHeader()
    });
    return response;
  };

  getAllBusinessActivities = async () => {
    const response = await axios.get(`${API_URL}/businessActivity/findAll`, {
      headers: authHeader()
    });
    return response;
  };
}

class FileStorageServices {
  uploadAttachment = async (file, fileName) => {
    const response = await axios.post(`${API_URL_BASE}/fileStorage/upload`, file, {
      headers: authHeaderMultiPart()
    });
    return response;
  };

  dowloadAttachment = async fileName => {
    const response = await axios.get(`${API_URL_BASE}/fileStorage/download?filename=${fileName}`, {
      headers: authHeader()
    });
    return response;
  };
}

class FiscalYearServices {
  getAllFiscalYears = async () => {
    const response = await axios.get(`${API_URL}/fiscalYear/findAll`, {
      headers: authHeader()
    });
    return response;
  };
}

class LedgerServices {
  addNewLedgerEntry = async newLedgerEntry => {
    console.log(API_URL);
    const response = await axios.post(`${API_URL}/ledgerEntry`, newLedgerEntry, {
      headers: authHeader()
    });
    return response;
  };

  getAllLedgerBetween = async (accountHead, startDate, endDate, pageNumber = 0, pageSize = 10) => {
    const response = await axios.get(
      `${API_URL}/ledgerEntry/${accountHead}?startDate=${startDate}&endDate=${endDate}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
      {
        headers: authHeader()
      }
    );
    return response;
  };
}

class LocationServices {
  getMunicipalityByDistrict = async districtName => {
    const response = await axios.get(
      `${API_URL}/location/municipality?districtName=${districtName}`,
      {
        headers: authHeader()
      }
    );
    return response;
  };

  getToleByAddress = async (districtName, municipalityName, wardNum) => {
    const response = await axios.get(
      `${API_URL}/location/tole?districtName=${districtName}&municipalityName=${municipalityName}&wardNum=${wardNum}`,
      {
        headers: authHeader()
      }
    );
    return response;
  };
}

class BalanceServices {
  getQuarterlyBalance = async (fiscalYear, quarterly) => {
    const response = await axios.get(
      `${API_URL}/balance/balanceSheet?fiscalYear=${fiscalYear}&quarterly=${quarterly}`,
      {
        headers: authHeader()
      }
    );
    return response;
  };

  getQuarterlyClashFlowStatement = async (fiscalYear, quarterly) => {
    const response = await axios.get(
      `${API_URL}/balance/clashFlowStatement?fiscalYear=${fiscalYear}&quarterly=${quarterly}`,
      {
        headers: authHeader()
      }
    );
    return response;
  };

  getQuarterlyChangeInEquity = async (fiscalYear, quarterly) => {
    const response = await axios.get(
      `${API_URL}/balance/changeInEquity?fiscalYear=${fiscalYear}&quarterly=${quarterly}`,
      {
        headers: authHeader()
      }
    );
    return response;
  };

  updateQuarterlyClosingBalance = async (fiscalYear, quarterly, updatedData) => {
    const response = await axios.put(
      `${API_URL}/balance/closing?fiscalYear=${fiscalYear}&quarterly=${quarterly}`,
      updatedData,
      {
        headers: authHeader()
      }
    );
    return response;
  };

  updateQuarterlyClosingBalanceOfRetainedEarning = async (fiscalYear, quarterly, updatedData) => {
    const response = await axios.put(
      `${API_URL}/balance/closing/retainedEarning?fiscalYear=${fiscalYear}&quarterly=${quarterly}`,
      updatedData,
      {
        headers: authHeader()
      }
    );
    return response;
  };

  getQuarterlyIncomeStatement = async (fiscalYear, quarterly) => {
    const response = await axios.get(
      `${API_URL}/balance/incomeStatement?fiscalYear=${fiscalYear}&quarterly=${quarterly}`,
      {
        headers: authHeader()
      }
    );
    return response;
  };

  updateQuarterlyOpeningBalance = async (previousFiscalYear, previousQuarterly) => {
    const response = await axios.put(
      `${API_URL}/balance/opening?previousFiscalYear=${previousFiscalYear}&previousQuarterly=${previousQuarterly}`,
      {},
      {
        headers: authHeader()
      }
    );
    return response;
  };

  getTrailBalance = async (fiscalYear, quarterly) => {
    const response = await axios.get(
      `${API_URL}/balance/trailBalance?fiscalYear=${fiscalYear}&quarterly=${quarterly}`,
      {
        headers: authHeader()
      }
    );
    return response;
  };

  updateTodayLedger = async (date, updatedData) => {
    const response = await axios.put(`${API_URL}/balance/updateLedger?date=${date}`, updatedData, {
      headers: authHeader()
    });
    return response;
  };
}

class ProductServices {
  addProduct = async newProduct => {
    const response = await axios.post(`${API_URL}/product`, newProduct, {
      headers: authHeader()
    });
    return response;
  };

  deleteProductById = async productId => {
    const response = await axios.delete(`${API_URL}/product/delete/${productId}`, {
      headers: authHeader()
    });
    return response;
  };

  getProductById = async productId => {
    const response = await axios.get(`${API_URL}/product/find/${productId}`, {
      headers: authHeader()
    });
    return response;
  };

  getAllProduct = async (pageNumber = 0, pageSize = 10) => {
    const response = await axios.get(
      `${API_URL}/product/findAll?pageNumber=${pageNumber}&pageSize=${pageSize}`,
      {
        headers: authHeader()
      }
    );
    return response;
  };
}

class TransactionServices {
  getAllTransactions = async (transactionNumber, transactionType) => {
    const response = await axios.get(
      `${API_URL}/transaction?transactionNumber=${transactionNumber}&transactionType=${transactionType}`,
      {
        headers: authHeader()
      }
    );
    return response;
  };

  addBill = async newBill => {
    const response = await axios.post(`${API_URL}/transaction/bill`, newBill, {
      headers: authHeader()
    });
    return response;
  };

  addCreditNote = async newCreditNote => {
    const response = await axios.post(`${API_URL}/transaction/creditNote`, newCreditNote, {
      headers: authHeader()
    });
    return response;
  };

  addDebitNote = async newDebitNote => {
    const response = await axios.post(`${API_URL}/transaction/debitNote`, newDebitNote, {
      headers: authHeader()
    });
    return response;
  };

  getAllPayment = async () => {
    const response = await axios.get(`${API_URL}/transaction/findAll/payment`, {
      headers: authHeader()
    });
    return response;
  };

  getAllReceipt = async () => {
    const response = await axios.get(`${API_URL}/transaction/findAll/receipt`, {
      headers: authHeader()
    });
    return response;
  };

  addNewInvoice = async newInvoice => {
    const response = await axios.post(`${API_URL}/transaction/invoice`, newInvoice, {
      headers: authHeader()
    });
    return response;
  };

  addPayment = async payment => {
    const response = await axios.post(`${API_URL}/transaction/payment`, payment, {
      headers: authHeader()
    });
    return response;
  };

  getPaymentWithTransactionNumber = async transactionNumber => {
    const response = await axios.get(`${API_URL}/transaction/payment/${transactionNumber}`, {
      headers: authHeader()
    });
    return response;
  };

  addReceipt = async newReceipt => {
    const response = await axios.post(`${API_URL}/transaction/receipt`, newReceipt, {
      headers: authHeader()
    });
    return response;
  };

  getReceiptWithTransactionNumber = async transactionNumber => {
    const response = await axios.get(`${API_URL}/transaction/receipt/${transactionNumber}`, {
      headers: authHeader()
    });
    return response;
  };

  getTransactionDetailForUpdate = async transactionNumber => {
    const response = await axios.get(
      `${API_URL}/transaction/receipt/update?transactionNumber=${transactionNumber}`,
      {
        headers: authHeader()
      }
    );
    return response;
  };

  updateTransaction = async (transactionNumber, updatedData) => {
    const response = await axios.put(`${API_URL}/transaction/update`, updatedData, {
      headers: authHeader()
    });
    return response;
  };

  addTransactionType = async newTransactionType => {
    const response = await axios.post(`${API_URL}/transactionType`, newTransactionType, {
      headers: authHeader()
    });
    return response;
  };

  getAllTransactionsType = async () => {
    const response = await axios.get(`${API_URL}/transactionType/findAll`, {
      headers: authHeader()
    });
    return response;
  };
}

class MeasurementServices {
  addUnitOfMeasurement = async newUnitOfMeasurement => {
    const response = await axios.post(`${API_URL}/unitOfMeasurement`, newUnitOfMeasurement, {
      headers: authHeader()
    });
    return response;
  };

  getAllUnitOfMeasurement = async () => {
    const response = await axios.get(`${API_URL}/unitOfMeasurement/findAll`, {
      headers: authHeader()
    });
    return response;
  };

  getUnitOfMeasurementLike = async str => {
    const response = await axios.get(`${API_URL}/unitOfMeasurement/like/${str}`, {
      headers: authHeader()
    });
    return response;
  };
}

class SessionServices {
  isTokenExpired = () => {
    const response = axios.get(`${API_URL}expired?token=${authToken}`);
    return response;
  };
}

export {
  AccountHeadServices,
  BalanceServices,
  ProductServices,
  TransactionServices,
  MeasurementServices,
  AccountSubCategoryServices,
  BusinessActivityServices,
  LedgerServices
};
