// External Import
import React, { useState } from 'react';
import { Snackbar, Alert } from '@mui/material';

// Custom Hook to handle Alerts in the app using context
export const useAlert = () => {
  const [alertState, setAlertState] = useState({});

  const handleSuccessClose = () => {
    setAlertState(alertState => (alertState = { ...alertState, open: false }));
  };

  const changeAlertState = alertState => {
    setAlertState(alertState);
  };

  return { alertState, changeAlertState, handleSuccessClose };
};
