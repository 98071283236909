// External Dependencies
import React from "react";
import { Link } from "react-router-dom";

const ForgotPassword = () => {
  return (
    <div>
      <h1>Forgot Password</h1>
      <Link to="/login">Sign In</Link>
    </div>
  );
};

export default ForgotPassword;
