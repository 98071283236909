// External Dependencies
import React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import FormatListNumberedRtlIcon from "@mui/icons-material/FormatListNumberedRtl";
import MedicationIcon from "@mui/icons-material/Medication";
import { useNavigate, Routes, Route } from "react-router-dom";
import theme from '../../../context/Theme.js';
// Routes
import Dashboard from "../../dashboard/Dashboard";

// Accounting Routes
import Accounting from "../../accounting/Accounting";
import AccountingHeads from "../../accounting/AccountingHeads";
import AccountingLedger from "../../accounting/AccountingLedger/AccountingLedger";
import AccountingExpense from "../../accounting/AccountingExpense";
import AccountingTransaction from "../../accounting/AccountingTransaction/AccountingTransaction";

// Analytics Routes
import Analytics from "../../analytics/Analytics";
import AnalyticsApproved from "../../analytics/AnalyticsApproved";
import AnalyticsDraft from "../../analytics/AnalyticsDraft";
import AnalyticsSubmit from "../../analytics/AnalyticsSubmit";

// Contacts Routes
import Contacts from "../../contacts/Contacts";

// Reports Routes
import Reports from "../../reports/Reports";
import ReportsBalance from "../../reports/ReportsBalance";
import ReportsCashflow from "../../reports/ReportsCashflow";
import ReportsPIL from "../../reports/ReportsPil";
import ReportsStock from "../../reports/ReportsStock";
import { useEffect, useState } from "react";
import { Grid } from "@mui/material";

const drawerWidth = 250;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const HeadingAndSidebar = (props) => {
  let navigate = useNavigate();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 1000) {
        setOpen(false);
      } else {
        setOpen();
      }
    }

    window.addEventListener("resize", handleResize);
  });

  const itemsList = [
    {
      text: "Dashboard",
      icon: <DashboardIcon />,
      onClick: () => navigate("/books"),
    },
    {
      text: "Account Expense",
      icon: <AccountBalanceWalletIcon />,
      onClick: () => navigate("/books/accounting/expense"),
    },
    {
      text: "Accounting Heads",
      icon: <FormatListBulletedIcon />,
      onClick: () => navigate("/books/accounting/head"),
    },
    {
      text: "Accounting Ledger",
      icon: <FormatListNumberedRtlIcon />,
      onClick: () => navigate("/books/accounting/ledger"),
    },
    {
      text: "Accounting Transaction",
      icon: <MedicationIcon />,
      onClick: () => navigate("/books/accounting/transaction"),
    },
  ];

  return (
    <Grid>
      {/* <CssBaseline /> */}
      <AppBar position="fixed" open={open} elevation={0}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Amtrix Books
          </Typography>
        </Toolbar>
      </AppBar>

      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {itemsList.map((item, index) => {
            const { text, icon, onClick } = item;
            return (
              <ListItemButton onClick={onClick} key={text}>
                {icon && <ListItemIcon>{icon}</ListItemIcon>}
                <ListItemText primary={text} />
              </ListItemButton>
            );
          })}
        </List>
      </Drawer>

      <Grid container spacing={2} xs={12} justifyContent="center" alignItems="center" sx={{paddingTop:'6rem'}}>
        {/* <DrawerHeader /> */}
          <Grid item container xs={8} md={6} sm={12} spacing={2} >
              <Routes>
                <Route path="/">
                  <Route index element={<Dashboard />} />
                  <Route path="accounting">
                    <Route index element={<Accounting />} />
                    <Route path="expense" element={<AccountingExpense />} />
                    <Route path="head" element={<AccountingHeads />} />
                    <Route path="ledger" element={<AccountingLedger />} />
                    <Route path="transaction" element={<AccountingTransaction />} />
                  </Route>

                  <Route path="analytics">
                    <Route index element={<Analytics />} />
                    <Route path="approved" element={<AnalyticsApproved />} />
                    <Route path="draft" element={<AnalyticsDraft />} />
                    <Route path="submit" element={<AnalyticsSubmit />} />
                  </Route>

                  <Route path="contacts">
                    <Route index element={<Contacts />} />
                  </Route>
                  <Route path="reports">
                    <Route index element={<Reports />} />
                    <Route path="balance" element={<ReportsBalance />} />
                    <Route path="cashflow" element={<ReportsCashflow />} />
                    <Route path="pil" element={<ReportsPIL />} />
                    <Route path="stock" element={<ReportsStock />} />
                  </Route>
              </Route>
            </Routes>
          </Grid>
      </Grid>
    </Grid>
  );
};

export default HeadingAndSidebar;
