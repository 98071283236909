// Internal Dependencies
import BooksRoute from "./routes/BookRoute";
import { useAlert } from "./hooks/useAlert";
import { AlertContext } from "./context/AlertContext";
// import { AlertNotification } from './amtrixbooks/sharedComponents/Alerts';
import Theme from "./context/Theme";
import { ThemeProvider } from "@mui/material";
import React from "react";

function App() {
  const { alertState, changeAlertState, handleSuccessClose } = useAlert();

  return (
    <ThemeProvider theme={Theme}>
      <AlertContext.Provider
        value={{ alertState, changeAlertState, handleSuccessClose }}
      >
        <BooksRoute/>
      </AlertContext.Provider>
    </ThemeProvider>
  );
}

export default App;
