import { Container } from "@mui/system";
import React from "react";
import { PageTittles } from "../sharedComponents/pageTitle";

const AccountingExpense = () => {
  return (
    <Container>
      <PageTittles headingTitle="Accounting Expense" />
    </Container>
  );
};

export default AccountingExpense;
