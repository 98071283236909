import React, { useState } from "react";
import "../login/login.css";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import { Link } from "react-router-dom";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { IconButton, InputAdornment, ListItem, TextField } from "@mui/material";
import {
  ArrowForwardRounded,
  Visibility,
  VisibilityOff
} from "@mui/icons-material";

const Login = () => {
  const handleClickShowPassword = () => {
    setTogglePassword(!togglePassword);
  };
  const [togglePassword, setTogglePassword] = useState(false);

  return (
    <Grid xs={12} container className="side-panel-bg">
      {/* <AlertNotification
        onChange={handleAlertClose}
        openState={alertCondidtion}
      /> */}

      <Grid item container alignItems="center" justifyContent="center" xs={12}>
        <Grid item xs={10} md={10} sm={10}>
          <Grid item xs={12} sm={9} md={4}>
            <Card variant="outlined">
              <CardContent>
                <Grid container xs={12}>
                  <Grid item container spacing={2} xs={12}>
                    <ListItem>
                      <Typography variant="h5">
                        <strong>Welcome!</strong>
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography variant="body1">
                        New here? <Link to="/signup">Create Account.</Link>
                      </Typography>
                    </ListItem>
                  </Grid>
                  {/* <form onSubmit={handleSubmit(handlelogin)}> */}
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <TextField
                          label="Username/Email:"
                          type="text"
                          name="username_email"
                          margin="dense"
                          variant="outlined"
                          // inputRef={register({ required: true })}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <TextField
                          label="Password:"
                          margin="dense"
                          name="password"
                          type={togglePassword ? "text" : "password"}
                          // inputRef={register({ required: true })}
                          variant="outlined"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment>
                                <IconButton
                                  edge="end"
                                  onClick={handleClickShowPassword}
                                  // onMouseDown={handleMouseDownPassword}
                                >
                                  {togglePassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        style={{ width: "100%" }}
                        disableElevation
                        size="large"
                        variant="contained"
                        color="primary"
                        type="submit"
                        endIcon={<ArrowForwardRounded />}
                        // disabled={loading}
                        onClick={() => {
                          // history.push("/signup");
                          window.location.reload();
                        }}
                      >
                        {" "}
                        Sign In
                      </Button>
                    </Grid>
                  </Grid>
                  {/* </form> */}

                  <Grid
                    item
                    xs={12}
                    style={{ textAlign: "center", paddingTop: "1rem" }}
                  >
                    <Link to="/resetpassword">
                      Having Trouble in Sign In? Reset Password
                    </Link>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Login;
