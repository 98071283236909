import { Card, CardContent, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import { PageTittles } from "../sharedComponents/pageTitle";

const Dashboard = () => {
  return (
    <>
      <Grid item xs={12}>
        <PageTittles
          headingTitle="Amtrix Books Dashboard"
          subtitleText={`Hi ! Welcome Back. 👋`}
        />
      </Grid>
        <Grid direction="row" item container xs={12} spacing={2}>
          <Grid item xs={6}>
            <Card elevation={0} variant="outlined">
              <CardContent>
                <Grid item container spacing={2}>
                  <Grid item sm={12} md={12} xs={12}>
                    <Typography variant="h6">
                      <b>Cash Flow Trends</b>
                    </Typography>
                    <Typography>Trends to look on Cash Account</Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card elevation={0} variant="outlined">
              <CardContent>
                <Grid item container spacing={2}>
                  <Grid item sm={12} md={12} xs={12}>
                    <Typography variant="h6">
                      <b>Invoice Receivable</b>
                    </Typography>
                    <Typography>Trends to look on Cash Account</Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Grid direction="row" item container xs={12}>
          <Grid item xs={12}>
            <Card elevation={0} variant="outlined">
              <CardContent>
                <Typography variant="h6">Bank Account View</Typography>
                <Typography>Trends to look on Cash Account</Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
    </>
  );
};

export default Dashboard;
