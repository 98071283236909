import { createTheme } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";

const fontWeight = {
  thin: 100,
  light: 300,
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
  extraBold: 800,
};

const fontSize = {
  0: "0.0625rem",
  1: "0.06875rem",
  2: "0.75rem",
  3: "0.8125rem",
  4: "0.875rem",
  5: "1rem",
  6: "1.25rem",
  7: "1.5rem",
  8: "1.75rem",
  9: "2rem",
  10: "2.5rem",
  11: "3rem",
  12: "3.5rem",
  13: "4rem",
  14: "4.5rem",
  15: "5rem",
  16: "5.5rem",
  17: "6rem",
};

const spaces = {
  0: "0.25rem",
  1: "0.5rem",
  2: "0.75rem",
  3: "1rem",
  4: "1.25rem",
  5: "1.5rem",
  6: "2rem",
  7: "2.5rem",
  8: "3rem",
  9: "4rem",
  10: "5rem",
  11: "6rem",
  12: "8rem",
  13: "10rem",
  14: "12rem",
  15: "14rem",
  16: "16rem",
};
const color = {
  // Solid Color
  black: "#000",
  white: "#fff",

  // Blue
  blue: {
    0: "#f0f6ff",
    10: "#e3edff",
    20: "#cddfff",
    30: "#b2ceff",
    40: "#97bdff",
    50: "#7cabff",
    60: "#639bff",
    70: "#4d8dff",
    80: "#3880ff",
    90: "#1b6dff",
    100: "#3971f1",
  },

  // Gray
  gray: {
    0: "#f3f3f3",
    10: "#e4e4e4",
    20: "#c8c8c8",
    30: "#aeaeae",
    40: "#959595",
    50: "#818181",
    60: "#6d6d6d",
    70: "#5f5f5f",
    80: "#474747",
    90: "#2f2f2f",
    100: "#141414",
  },

  //Carbon
  carbon: {
    0: "#eef1f3",
    10: "#d7dde2",
    20: "#b4bcc6",
    30: "#98a2ad",
    40: "#7d8894",
    50: "#677483",
    60: "#556170",
    70: "#434f5e",
    80: "#35404e",
    90: "#222d3a",
    100: "#03060b",
  },

  //Indigo
  indigo: {
    0: "#fbfbfd",
    10: "#f6f8fc",
    20: "#e9edf3",
    30: "#dee3ea",
    40: "#ced6e0",
    50: "#b2becd",
    60: "#92a0b3",
    70: "#73849a",
    80: "#445b78",
    90: "#2d4665",
    100: "#001a3a",
  },

  //Green
  green: {
    0: "#f1fdf5",
    10: "#deffe7",
    20: "#c7fbd5",
    30: "#a7f1bb",
    40: "#80e89d",
    50: "#62e085",
    60: "#4ada71",
    70: "#2dd55b",
    80: "#17c948",
    90: "#00ba33",
    100: "#00a52d",
  },

  //Lime
  lime: {
    0: "#f5fff0",
    10: "#ebfee3",
    20: "#ddfcd0",
    30: "#cffbbc",
    40: "#bbf9a2",
    50: "#a3f581",
    60: "#8bf35f",
    70: "#64ec44",
    80: "#4ddf2b",
    90: "#3ad515",
    100: "#27c100",
  },

  //Lavendra
  lavendra: {
    0: "#f7f8ff",
    10: "#e6ebff",
    20: "#ced9ff",
    30: "#b6c6ff",
    40: "#9fb5ff",
    50: "#8aa4ff",
    60: "#7493ff",
    70: "#597eff",
    80: "#3c67ff",
    90: "#194bfd",
    100: "#0033e8",
  },

  //Purple
  purple: {
    0: "#f4f4ff",
    10: "#e9eaff",
    20: "#d0d2ff",
    30: "#b6b9f9",
    40: "#9a99fc",
    50: "#8482fb",
    60: "#786df9",
    70: "#6e5afd",
    80: "#6030ff",
    90: "#4712fb",
    100: "#3400e6",
  },

  //Pink
  pink: {
    0: "#ffeff5",
    10: "#ffe3ed",
    20: "#ffd8e5",
    30: "#ffc9db",
    40: "#ffb6d0",
    50: "#ff99bd",
    60: "#ff80ac",
    70: "#ff6098",
    80: "#fb4082",
    90: "#ec216a",
    100: "#da0d56",
  },

  //Red
  red: {
    0: "#fff1f3",
    10: "#ffe6e8",
    20: "#ffcfd3",
    30: "#feb7bc",
    40: "#fc9aa2",
    50: "#f9838c",
    60: "#f56570",
    70: "#f24c58",
    80: "#ef3442",
    90: "#e21827",
    100: "#d0000f",
  },

  //Orange
  orange: {
    0: "#fff5f0",
    10: "#ffede6",
    20: "#ffdfd1",
    30: "#ffd0bc",
    40: "#ffc0a5",
    50: "#ffaf8c",
    60: "#ff9b70",
    70: "#ff8753",
    80: "#ff7336",
    90: "#ff5b13",
    100: "#eb4700",
  },

  //Yellow
  yellow: {
    0: "#fffbef",
    10: "#fff8e2",
    20: "#fff4d1",
    30: "#ffefbd",
    40: "#ffe9a3",
    50: "#ffe080",
    60: "#ffd75a",
    70: "#ffce31",
    80: "#ffc409",
    90: "#f4b100",
    100: "#eaa100",
  },

  //Aqua
  aqua: {
    0: "#f0fff9",
    10: "#e6fff6",
    20: "#ceffed",
    30: "#b7fce3",
    40: "#93f9d5",
    50: "#79f5c9",
    60: "#59f0ba",
    70: "#38e9aa",
    80: "#1ae19a",
    90: "#00d287",
    100: "#00ba78",
  },

  //Teal
  teal: {
    0: "#eefeff",
    10: "#dffdff",
    20: "#d0fdff",
    30: "#bbfcff",
    40: "#a2fcff",
    50: "#8bfbff",
    60: "#73f6fb",
    70: "#55ecf2",
    80: "#35e2e9",
    90: "#1bd2d9",
    100: "#00b9c0",
  },

  //Cyan
  cyan: {
    0: "#f3faff",
    10: "#e8f5ff",
    20: "#d3ecff",
    30: "#bfe4ff",
    40: "#a7daff",
    50: "#8dcfff",
    60: "#77c6ff",
    70: "#62bdff",
    80: "#46b1ff",
    90: "#24a3ff",
    100: "#0091fa",
  },

  //Hero
  hero: {
    dark: "#020024",
    light: "#3051AA",
  },
};

const Theme = createTheme({
  palette: {
    // mode:'dark',
    primary: {
      main: `${color.blue[100]}`,
    },
    text:{
      primary:`${color.indigo[100]}`
    }
  },
  typography: {
    button: {
      textTransform: "none",
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiTextField: {
      defaultProps: {
        size: "small",
        fullWidth: true,
      },
    },
    MuiCard:{
      defaultProps:{
        elevation:0,
        variant:'outlined'
      },
      styleOverrides:{
        root:{
          borderRadius:`${spaces[2]}`
        }
      }
    }
  },
});

export default Theme;
