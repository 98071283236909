// External Dependencies
import React, { useState, useContext, useEffect } from "react";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import {
  AccountSubCategoryServices,
  AccountHeadServices,
  BusinessActivityServices,
} from "../../authentication/services/amtrix.books.services";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

// @MUI Import
import {
  Grid,
  Card,
  TextField,
  Button,
  Typography,
  FormControl,
  CardContent,
  Autocomplete,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

// Internal Import
import { AlertContext } from "../../context/AlertContext";
import { PageTittles } from "../sharedComponents/pageTitle";

const accountSubCategoryServices = new AccountSubCategoryServices();
const accountHeadServices = new AccountHeadServices();
const businessActivityServices = new BusinessActivityServices();

const AccountingHeads = () => {
  const [accountCategoryTypes, setAccountCategoryTypes] = useState([]);
  const [businessActivityTypes, setBusinessActivityTypes] = useState([]);
  const { changeAlertState } = useContext(AlertContext);
  const [isBank, setIsBank] = useState(false);

  useEffect(() => {
    const getAllAccountCategory = async () => {
      try {
        const response =
          await accountSubCategoryServices.getAllAccountSubCategory();
        setAccountCategoryTypes(response.data.object);
      } catch (error) {
        changeAlertState({
          open: true,
          message: error.message,
          severity: "error",
        });
      }
    };

    const getAllBusinessActivities = async () => {
      try {
        const response =
          await businessActivityServices.getAllBusinessActivities();
        setBusinessActivityTypes(response.data.object);
      } catch (error) {
        changeAlertState({
          open: true,
          message: error.message,
          severity: "error",
        });
      }
    };

    getAllBusinessActivities();
    getAllAccountCategory();
  }, []);

  const AccountCreateSchema = Yup.object().shape({
    accountName: Yup.string().required("Account Head is required"),
    accountCategory: Yup.string().required("Category is required"),
    openingBalance: Yup.number(),
    openingBalanceType: Yup.string().required(
      "Opening Balance Type is required"
    ),
    legalDetails: Yup.object()
      .shape({
        companyName: Yup.string().required("Company Name is required"),
        panNumber: Yup.string().required("PAN Number is required"),
        companyRegistrationNumber: Yup.string().required(
          "Company Registration Number is required"
        ),
      })
      .required("Legal Details is required"),
    contactDetails: Yup.object().shape({
      phoneNumber: Yup.string().required("Phone Number is required"),
      emailAddress: Yup.string().required("Email is required"),
    }),
    billingAddress: Yup.object().shape({
      state: Yup.string().required("State is required"),
      district: Yup.string().required("District is required"),
      municipality: Yup.string().required("Municipality is required"),
      wardNo: Yup.number().required("required"),
      streetName: Yup.string().required("Street Name is required"),
    }),
    isShippingAddressSame: Yup.boolean().required(),
    shippingAddress: Yup.object().when("isShippingAddressSame", {
      is: false,
      then: Yup.object().shape({
        state: Yup.string().required("State is required"),
        district: Yup.string().required("District is required"),
        municipality: Yup.string().required("Municipality is required"),
        wardNo: Yup.number().required("required"),
        streetName: Yup.string().required("Street Name is required"),
      }),
    }),
    businessActivity: Yup.string().required("Business Activity is required"),
  });

  // Validation when bank is selected in category
  const bankValidation = Yup.object().shape({
    accountName: Yup.string().required("Account Head is required"),
    accountCategory: Yup.string().required("Category is required"),
    openingBalance: Yup.string().required("Balance is required"),
    openingBalanceType: Yup.string().required(
      "Opening Balance Type is required"
    ),
    bankBranchName: Yup.string(),
    bankBranchAddress: Yup.string(),
    bankAccountNumber: Yup.string(),
    businessActivity: Yup.string().required("Business Activity is required"),
  });

  const [schemaValidation, setSchemaValidation] = useState(AccountCreateSchema);

  useEffect(() => {
    if (isBank) {
      setSchemaValidation(bankValidation);
    } else {
      setSchemaValidation(AccountCreateSchema);
    }
  }, [isBank]);

  const formik = useFormik({
    initialValues: {
      accountName: "",
      accountCategory: "",
      openingBalance: 0,
      businessActivity: "",
      openingBalanceType: "",
      legalDetails: {
        panNumber: "",
        companyName: "",
        companyRegistrationNumber: "",
      },
      contactDetails: {
        phoneNumber: "",
        emailAddress: "",
      },
      billingAddress: {
        state: "",
        district: "",
        municipality: "",
        wardNo: "",
        streetName: "",
      },
      isShippingAddressSame: false,
      shippingAddress: {
        state: "",
        district: "",
        municipality: "",
        wardNo: "",
        streetName: "",
      },
      companyPhone: "",
      companyEmail: "",
      bankBranchName: "",
      bankBranchAddress: "",
      bankAccountNumber: "",
    },
    validationSchema: isBank ? bankValidation : AccountCreateSchema,
    // Show the alert state when the form is submitted
    onSubmit: (values) => {
      const allCategories = accountCategoryTypes.map(
        (category) => category.name
      );
      if (!allCategories.includes(values.accountCategory)) {
        changeAlertState({
          open: true,
          message: "Please select a valid category",
          severity: "error",
        });
        formik.setFieldValue("accountCategory", "");
        errors.accountCategory = "Please select a valid category";
        formik.setSubmitting(false);
        return;
      }

      const accountDataValues = {
        accountHead: values.accountName,
        accountSubCategory: values.accountCategory,
        businessActivity: values.businessActivity,
        openingBalance: values.openingBalance,
        openingBalanceType: values.openingBalanceType,
        legalDetails: values.legalDetails,
        accountDetails: values.contactDetails,
      };

      const bankDataValues = {
        accountHead: values.accountName,
        accountSubCategory: values.accountCategory,
        businessActivity: values.businessActivity,
        openingBalance: values.openingBalance,
        openingBalanceType: values.openingBalanceType,
        bankBranchName: values.bankBranchName,
        bankBranchAddress: values.bankBranchAddress,
        bankAccountNumber: values.bankAccountNumber,
      };

      const dataValues = isBank ? bankDataValues : accountDataValues;

      const createAccountHead = async () => {
        try {
          const response = await accountHeadServices.saveAccountHead(
            dataValues
          );
          if (response.status === 200) {
            changeAlertState({
              open: true,
              message: response.data.message,
              severity: "success",
            });
            formik.setSubmitting(false);
            formik.resetForm();
          } else {
            changeAlertState({
              open: true,
              message: response.data.message,
              severity: "error",
            });
            formik.setSubmitting(false);
          }
        } catch (error) {
          changeAlertState({
            open: true,
            message: error.message,
            severity: "error",
          });
          formik.setSubmitting(false);
        }
      };

      createAccountHead();
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } =
    formik;

  useEffect(() => {
    console.log("values", values);
    console.log(errors);
    if (values.accountCategory.toLowerCase() === "bank account") {
      setIsBank(true);
    } else {
      setIsBank(false);
    }
  }, [values]);

  const clearForm = () => {
    formik.resetForm();
  };

  const handleSameAsAboveAddressCheckboxClicked = (e) => {
    if (e.target.checked) {
      formik.setFieldValue("isShippingAddressSame", true);
    } else {
      formik.setFieldValue("isShippingAddressSame", false);
    }
  };

  return (
    <>
      <Grid item xs={12}>
        <PageTittles
          headingTitle="Account Heads"
          subtitleText={`subTitle Here`}
        />
      </Grid>
      <Grid item xs={12}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Card elevation={0} variant="outlined">
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item container sm={12} md={12} xs={12}>
                    <Grid item direction="row" sm={12} md={12} xs={12}>
                      <Typography>
                        <b>Account Creation</b>
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid item container sm={12} md={12} xs={12} spacing={2}>
                    <Grid item sm={6} md={4} xs={12}>
                      <FormControl fullWidth size="small">
                        <TextField
                          id="accountName"
                          label="Account Name"
                          variant="outlined"
                          size="small"
                          fullWidth
                          {...getFieldProps("accountName")}
                          error={Boolean(
                            touched.accountName && errors.accountName
                          )}
                          helperText={touched.accountName && errors.accountName}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={6} md={4} xs={12}>
                      <FormControl fullWidth size="small">
                        <TextField
                          id="account-type-select"
                          label="Account Category"
                          variant="outlined"
                          size="small"
                          fullWidth
                          {...getFieldProps("accountCategory")}
                          error={Boolean(
                            touched.accountCategory && errors.accountCategory
                          )}
                          helperText={
                            touched.accountCategory && errors.accountCategory
                          }
                          select
                        >
                          {accountCategoryTypes.length > 0 &&
                            accountCategoryTypes.map((type) => {
                              return [
                                <MenuItem value={type.name}>
                                  {type.name}
                                </MenuItem>,
                              ];
                            })}
                        </TextField>
                      </FormControl>
                    </Grid>
                    <Grid item sm={6} md={4} xs={12}>
                      <FormControl fullWidth size="small">
                        <TextField
                          id="business-type-select"
                          label="Business Activity Type"
                          variant="outlined"
                          size="small"
                          fullWidth
                          {...getFieldProps("businessActivity")}
                          error={Boolean(
                            touched.businessActivity && errors.businessActivity
                          )}
                          helperText={
                            touched.businessActivity && errors.businessActivity
                          }
                          select
                        >
                          {businessActivityTypes.length > 0 &&
                            businessActivityTypes.map((type) => {
                              return [
                                <MenuItem value={type.name}>
                                  {type.name}
                                </MenuItem>,
                              ];
                            })}
                        </TextField>
                      </FormControl>
                    </Grid>
                    <Grid item sm={6} md={4} xs={12}>
                      <TextField
                        id="outlined-basic"
                        label="Opening Balance"
                        variant="outlined"
                        size="small"
                        type="number"
                        fullWidth
                        {...getFieldProps("openingBalance")}
                        error={Boolean(
                          touched.openingBalance && errors.openingBalance
                        )}
                        helperText={
                          touched.openingBalance && errors.openingBalance
                        }
                      />
                    </Grid>
                    <Grid item sm={6} md={4} xs={12}>
                      <TextField
                        id="outlined-basic"
                        label="Opening Balance Type"
                        variant="outlined"
                        size="small"
                        fullWidth
                        {...getFieldProps("openingBalanceType")}
                        error={Boolean(
                          touched.openingBalanceType &&
                            errors.openingBalanceType
                        )}
                        helperText={
                          touched.openingBalanceType &&
                          errors.openingBalanceType
                        }
                        select
                      >
                        <MenuItem value="debit">Debit</MenuItem>
                        <MenuItem value="credit">Credit</MenuItem>
                      </TextField>
                    </Grid>
                  </Grid>
                  {!isBank && (
                    <>
                      <Grid item container sm={12} md={12} xs={12}>
                        <Grid item direction="row" sm={12} md={12} xs={12}>
                          <Typography>
                            <b>Legal Details</b>
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item container sm={12} md={12} xs={12} spacing={2}>
                        <Grid item sm={6} md={4} xs={12}>
                          <TextField
                            id="panNumber"
                            label="PAN No:"
                            variant="outlined"
                            size="small"
                            fullWidth
                            {...getFieldProps("legalDetails.panNumber")}
                            error={Boolean(
                              touched.legalDetails &&
                                touched.legalDetails.panNumber &&
                                errors.legalDetails &&
                                errors.legalDetails.panNumber
                            )}
                            helperText={
                              touched.legalDetails &&
                              touched.legalDetails.panNumber &&
                              errors.legalDetails &&
                              errors.legalDetails.panNumber
                            }
                          />
                        </Grid>
                        <Grid item sm={6} md={4} xs={12}>
                          <TextField
                            id="companyName"
                            label="Company Name"
                            variant="outlined"
                            size="small"
                            fullWidth
                            {...getFieldProps("legalDetails.companyName")}
                            error={Boolean(
                              touched.legalDetails &&
                                touched.legalDetails.companyName &&
                                errors.legalDetails &&
                                errors.legalDetails.companyName
                            )}
                            helperText={
                              touched.legalDetails &&
                              touched.legalDetails.companyName &&
                              errors.legalDetails &&
                              errors.legalDetails.companyName
                            }
                          />
                        </Grid>
                        <Grid item sm={6} md={4} xs={12}>
                          <TextField
                            id="companyRegistrationNumber"
                            label="Company Reg Num"
                            variant="outlined"
                            size="small"
                            fullWidth
                            {...getFieldProps(
                              "legalDetails.companyRegistrationNumber"
                            )}
                            error={Boolean(
                              touched.legalDetails &&
                                touched.legalDetails
                                  .companyRegistrationNumber &&
                                errors.legalDetails &&
                                errors.legalDetails.companyRegistrationNumber
                            )}
                            helperText={
                              touched.legalDetails &&
                              touched.legalDetails.companyRegistrationNumber &&
                              errors.legalDetails &&
                              errors.legalDetails.companyRegistrationNumber
                            }
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}

                  <Grid item container sm={12} md={12} xs={12}>
                    <Grid item direction="row" sm={12} md={12} xs={12}>
                      <Typography>
                        <b>{isBank ? "Bank Details" : "Contact Details"}</b>
                      </Typography>
                    </Grid>
                  </Grid>
                  {isBank ? (
                    <Grid item container sm={12} md={12} xs={12} spacing={2}>
                      <Grid item md={4} sm={6} xs={12}>
                        <TextField
                          id="account-number"
                          label="Account Number:"
                          variant="outlined"
                          size="small"
                          fullWidth
                          {...getFieldProps("bankAccountNumber")}
                          error={Boolean(
                            touched.bankAccountNumber &&
                              errors.bankAccountNumber
                          )}
                          helperText={
                            touched.bankAccountNumber &&
                            errors.bankAccountNumber
                          }
                        />
                      </Grid>
                      <Grid item md={4} sm={6} xs={12}>
                        <TextField
                          id="bank-branch-name"
                          label="Branch Name:"
                          variant="outlined"
                          size="small"
                          fullWidth
                          {...getFieldProps("bankBranchName")}
                          error={Boolean(
                            touched.bankBranchName && errors.bankBranchName
                          )}
                          helperText={
                            touched.bankBranchName && errors.bankBranchName
                          }
                        />
                      </Grid>
                      <Grid item md={4} sm={6} xs={12}>
                        <TextField
                          id="bank-branch-address"
                          label="Branch Address:"
                          variant="outlined"
                          size="small"
                          fullWidth
                          {...getFieldProps("bankBranchAddress")}
                          error={Boolean(
                            touched.bankBranchAddress &&
                              errors.bankBranchAddress
                          )}
                          helperText={
                            touched.bankBranchAddress &&
                            errors.bankBranchAddress
                          }
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid item container sm={12} md={12} xs={12} spacing={2}>
                      <Grid item md={4} sm={6} xs={12}>
                        <TextField
                          id="phoneNumber"
                          label="Phone No:"
                          variant="outlined"
                          size="small"
                          fullWidth
                          {...getFieldProps("contactDetails.phoneNumber")}
                          error={Boolean(
                            touched.contactDetails &&
                              touched.contactDetails.phoneNumber &&
                              errors.contactDetails &&
                              errors.contactDetails.phoneNumber
                          )}
                          helperText={
                            touched.contactDetails &&
                            touched.contactDetails.phoneNumber &&
                            errors.contactDetails &&
                            errors.contactDetails.phoneNumber
                          }
                        />
                      </Grid>
                      <Grid item md={4} sm={6} xs={12}>
                        <TextField
                          id="outlined-basic"
                          label="Email Address:"
                          variant="outlined"
                          size="small"
                          fullWidth
                          {...getFieldProps("contactDetails.emailAddress")}
                          error={Boolean(
                            touched.contactDetails &&
                              touched.contactDetails.emailAddress &&
                              errors.contactDetails &&
                              errors.contactDetails.emailAddress
                          )}
                          helperText={
                            touched.contactDetails &&
                            touched.contactDetails.emailAddress &&
                            errors.contactDetails &&
                            errors.contactDetails.emailAddress
                          }
                        />
                      </Grid>
                    </Grid>
                  )}

                  {!isBank && (
                    <>
                      <Grid item container sm={12} md={12} xs={12}>
                        <Grid item direction="row" sm={12} md={12} xs={12}>
                          <Typography>
                            <b>Billing Address</b>
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid item container sm={12} md={12} xs={12} spacing={2}>
                        <Grid item md={2}>
                          <TextField
                            id="outlined-basic"
                            label="State"
                            variant="outlined"
                            size="small"
                            fullWidth
                            {...getFieldProps("billingAddress.state")}
                            error={Boolean(
                              touched.billingAddress &&
                                touched.billingAddress.state &&
                                errors.billingAddress &&
                                errors.billingAddress.state
                            )}
                            helperText={
                              touched.billingAddress &&
                              touched.billingAddress.state &&
                              errors.billingAddress &&
                              errors.billingAddress.state
                            }
                          />
                        </Grid>
                        <Grid item md={2}>
                          <TextField
                            id="outlined-basic"
                            label="State"
                            variant="outlined"
                            size="small"
                            fullWidth
                            {...getFieldProps("billingAddress.district")}
                            error={Boolean(
                              touched.billingAddress &&
                                touched.billingAddress.district &&
                                errors.billingAddress &&
                                errors.billingAddress.district
                            )}
                            helperText={
                              touched.billingAddress &&
                              touched.billingAddress.district &&
                              errors.billingAddress &&
                              errors.billingAddress.district
                            }
                          />
                        </Grid>
                        <Grid item md={4}>
                          <TextField
                            id="outlined-basic"
                            label="Municipality"
                            variant="outlined"
                            size="small"
                            fullWidth
                            {...getFieldProps("billingAddress.municipality")}
                            error={Boolean(
                              touched.billingAddress &&
                                touched.billingAddress.municipality &&
                                errors.billingAddress &&
                                errors.billingAddress.municipality
                            )}
                            helperText={
                              touched.billingAddress &&
                              touched.billingAddress.municipality &&
                              errors.billingAddress &&
                              errors.billingAddress.municipality
                            }
                          />
                        </Grid>
                        <Grid item md={1}>
                          <TextField
                            id="outlined-basic"
                            label="W No."
                            variant="outlined"
                            size="small"
                            type="number"
                            fullWidth
                            {...getFieldProps("billingAddress.wardNo")}
                            error={Boolean(
                              touched.billingAddress &&
                                touched.billingAddress.wardNo &&
                                errors.billingAddress &&
                                errors.billingAddress.wardNo
                            )}
                            helperText={
                              touched.billingAddress &&
                              touched.billingAddress.wardNo &&
                              errors.billingAddress &&
                              errors.billingAddress.wardNo
                            }
                          />
                        </Grid>
                        <Grid item md={3}>
                          <TextField
                            id="outlined-basic"
                            label="State"
                            variant="outlined"
                            size="small"
                            fullWidth
                            {...getFieldProps("billingAddress.streetName")}
                            error={Boolean(
                              touched.billingAddress &&
                                touched.billingAddress.streetName &&
                                errors.billingAddress &&
                                errors.billingAddress.streetName
                            )}
                            helperText={
                              touched.billingAddress &&
                              touched.billingAddress.streetName &&
                              errors.billingAddress &&
                              errors.billingAddress.streetName
                            }
                          />
                        </Grid>
                      </Grid>

                      <Grid item container sm={12} md={12} xs={12}>
                        <Grid item direction="row" sm={12} md={12} xs={12}>
                          <Typography>
                            <b>Shipping Address</b>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={values.isShippingAddressSame}
                                  onChange={
                                    handleSameAsAboveAddressCheckboxClicked
                                  }
                                />
                              }
                              label="Same as billing"
                            />
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid item container sm={12} md={12} xs={12} spacing={2}>
                        {!values.isShippingAddressSame && (
                          <>
                            <Grid item md={2}>
                              <TextField
                                id="outlined-basic"
                                label="State"
                                variant="outlined"
                                size="small"
                                fullWidth
                                {...getFieldProps("shippingAddress.state")}
                                error={Boolean(
                                  touched.shippingAddress &&
                                    touched.shippingAddress.state &&
                                    errors.shippingAddress &&
                                    errors.shippingAddress.state
                                )}
                                helperText={
                                  touched.shippingAddress &&
                                  touched.shippingAddress.state &&
                                  errors.shippingAddress &&
                                  errors.shippingAddress.state
                                }
                              />
                            </Grid>
                            <Grid item md={2}>
                              <TextField
                                id="outlined-basic"
                                label="State"
                                variant="outlined"
                                size="small"
                                fullWidth
                                {...getFieldProps("shippingAddress.district")}
                                error={Boolean(
                                  touched.shippingAddress &&
                                    touched.shippingAddress.district &&
                                    errors.shippingAddress &&
                                    errors.shippingAddress.district
                                )}
                                helperText={
                                  touched.shippingAddress &&
                                  touched.shippingAddress.district &&
                                  errors.shippingAddress &&
                                  errors.shippingAddress.district
                                }
                              />
                            </Grid>
                            <Grid item md={4}>
                              <TextField
                                id="outlined-basic"
                                label="Municipality"
                                variant="outlined"
                                size="small"
                                fullWidth
                                {...getFieldProps(
                                  "shippingAddress.municipality"
                                )}
                                error={Boolean(
                                  touched.shippingAddress &&
                                    touched.shippingAddress.municipality &&
                                    errors.shippingAddress &&
                                    errors.shippingAddress.municipality
                                )}
                                helperText={
                                  touched.shippingAddress &&
                                  touched.shippingAddress.municipality &&
                                  errors.shippingAddress &&
                                  errors.shippingAddress.municipality
                                }
                              />
                            </Grid>
                            <Grid item md={1}>
                              <TextField
                                id="outlined-basic"
                                label="W No."
                                variant="outlined"
                                size="small"
                                type="number"
                                fullWidth
                                {...getFieldProps("shippingAddress.wardNo")}
                                error={Boolean(
                                  touched.shippingAddress &&
                                    touched.shippingAddress.wardNo &&
                                    errors.shippingAddress &&
                                    errors.shippingAddress.wardNo
                                )}
                                helperText={
                                  touched.shippingAddress &&
                                  touched.shippingAddress.wardNo &&
                                  errors.shippingAddress &&
                                  errors.shippingAddress.wardNo
                                }
                              />
                            </Grid>
                            <Grid item md={3}>
                              <TextField
                                id="outlined-basic"
                                label="State"
                                variant="outlined"
                                size="small"
                                fullWidth
                                {...getFieldProps("shippingAddress.streetName")}
                                error={Boolean(
                                  touched.shippingAddress &&
                                    touched.shippingAddress.streetName &&
                                    errors.shippingAddress &&
                                    errors.shippingAddress.streetName
                                )}
                                helperText={
                                  touched.shippingAddress &&
                                  touched.shippingAddress.streetName &&
                                  errors.shippingAddress &&
                                  errors.shippingAddress.streetName
                                }
                              />
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </>
                  )}

                  <Grid
                    item
                    container
                    sm={12}
                    md={12}
                    xs={12}
                    spacing={2}
                    justifyContent="flex-end"
                  >
                    <Grid item>
                      <Button variant="outlined" onClick={clearForm}>
                        Clear
                      </Button>
                    </Grid>
                    <Grid item>
                      <LoadingButton
                        type="submit"
                        variant="contained"
                        loadingPosition="end"
                        loading={isSubmitting}
                      >
                        Save
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Form>
        </FormikProvider>
      </Grid>
    </>
  );
};

export default AccountingHeads;
