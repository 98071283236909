// External Dependencies
import React, { useState, useEffect, useContext } from "react";

// @MUI Import
import {
  Grid,
  Card,
  TextField,
  Button,
  CardContent,
  Typography,
  MenuItem,
  FormControl
} from "@mui/material/";

// Formik
import { useFormik, FormikProvider, Form } from "formik";

// Yup
import * as Yup from "yup";

// Internal Dependencies
import { AccountHeadServices } from "../../../../authentication/services/amtrix.books.services";
import { AlertContext } from "../../../../context/AlertContext";

const accountHeadServices = new AccountHeadServices();

const LedgerSearch = ({ fetchLedgerEntries }) => {
  const [accountNamesList, setAccountNamesList] = useState([]);
  const { changeAlertState } = useContext(AlertContext);

  useEffect(() => {
    const getAllAccountHeads = async () => {
      try {
        const response = await accountHeadServices.getAllAccountHeads();
        if (response.status === 200) {
          setAccountNamesList(response.data.object);
        } else {
          changeAlertState({
            open: true,
            severity: "error",
            message: "Error fetching account heads"
          });
        }
      } catch (error) {
        changeAlertState({
          open: true,
          message: error.message,
          severity: "error"
        });
      }
    };
    getAllAccountHeads();
  }, []);

  const AccountLedgerSchema = Yup.object().shape({
    accountName: Yup.string().required("Account Name is required"),
    fromDate: Yup.date().required("From Date is required"),
    toDate: Yup.date().required("To Date is required")
  });

  const formik = useFormik({
    initialValues: {
      accountName: "",
      // Substract ONe month from today date
      fromDate: new Date(new Date().setMonth(new Date().getMonth() - 1))
        .toISOString()
        .substring(0, 10),
      toDate: new Date().toISOString().substr(0, 10)
    },

    validationSchema: AccountLedgerSchema,
    onSubmit: async values => {
      fetchLedgerEntries({
        accountName: values.accountName,
        startDate: values.fromDate,
        endDate: values.toDate
      });
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <Grid item xs={12}>
    <FormikProvider value={formik}>
      <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
        <Grid direction='row' item xs={12}>
          <Card elevation={0} variant='outlined'>
            <CardContent>
              <Grid item container spacing={2}>
                <Grid item container sm={12} md={12} xs={12}>
                  <Grid item sm={12} md={12} xs={12}>
                    <Typography>
                      <b>Account Summary</b>
                    </Typography>
                  </Grid>
                  <Grid item container sm={12} xs={12} spacing={2}>
                    <Grid item sm={6} md={4} xs={12}>
                      <FormControl fullWidth sx={{ minWidth: 150 }} size='small'>
                        <TextField
                          id='accountName'
                          label='Account Name'
                          variant='outlined'
                          {...getFieldProps("accountName")}
                          error={Boolean(touched.accountName && errors.accountName)}
                          helperText={touched.accountName && errors.accountName}
                          select
                        >
                          {accountNamesList.length !== 0 &&
                            accountNamesList.map((accountObject, index) => {
                              return (
                                <MenuItem key={index} value={accountObject.name}>
                                  {accountObject.name}
                                </MenuItem>
                              );
                            })}
                        </TextField>
                      </FormControl>
                    </Grid>
                    <Grid item sm={6} md={3} xs={12}>
                      <TextField
                        id='fromDate'
                        type='date'
                        label='From Date:'
                        variant='outlined'
                        size='small'
                        {...getFieldProps("fromDate")}
                        error={Boolean(touched.fromDate && errors.fromDate)}
                        helperText={touched.fromDate && errors.fromDate}
                      />
                    </Grid>
                    <Grid item sm={6} md={3} xs={12}>
                      <TextField
                        id='toDate'
                        type='date'
                        label='To Date:'
                        variant='outlined'
                        size='small'
                        {...getFieldProps("toDate")}
                        error={Boolean(touched.toDate && errors.toDate)}
                        helperText={touched.toDate && errors.toDate}
                      />
                    </Grid>
                    <Grid item sm={6} md={2} xs={12}>
                      <Button type='submit' variant='contained' fullWidth>
                        Update
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Form>
    </FormikProvider>
    </Grid>
  );
};

export default LedgerSearch;
