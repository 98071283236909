import React from "react";
// @MUI Import
import {
  Grid,
  Card,
  CardContent,
  Button,
  Typography,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

const JournalEntryView = () => {
  return (
    <Grid direction="row" item container xs={12}>
      <Card>
        <CardContent>
          <Grid item container spacing={2}>
            <Grid item container xs={12}>
              <Grid item sm={12} md={12} xs={12}>
                <Typography>
                  <b>Transcation Entries</b>
                </Typography>
                <Typography>Demo Pharmacy</Typography>
                <Typography>
                  For the period of 1st June, 2021 to 17th May, 2022
                </Typography>
              </Grid>
            </Grid>

            <Grid item container xs={12} spacing={2}>
              <Grid item sm={6} md={4} xs={12}>
                <TextField
                  id="fromDate"
                  label="From Date:"
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item sm={6} md={4} xs={12}>
                <TextField
                  id="toDate"
                  label="To Date:"
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item sm={6} md={4} xs={12}>
                <Button variant="contained" disableElevation={true}>
                  Update
                </Button>
              </Grid>
            </Grid>

            <Grid item sm={12} md={12} xs={12}>
              <TableContainer>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Txn No.</TableCell>
                      <TableCell>Particulars</TableCell>
                      <TableCell>Debit</TableCell>
                      <TableCell>Credit</TableCell>
                      <TableCell>Description</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default JournalEntryView;
