// External Dependencies
import React, { useContext } from "react";
import { Routes, Route } from "react-router-dom";
import LandingComponent from "../amtrixbooks/sharedComponents/landing/LandingComponent";

import { AlertContext } from "../context/AlertContext";

// Component Import For Route
import { Login, Signup, ForgotPassword } from "../authentication";
import { AlertNotification } from "../amtrixbooks/sharedComponents/Alerts";

const BookRoute = () => {
  const { alertState, handleSuccessClose } = useContext(AlertContext);
  return (
    <>
      <AlertNotification openState={alertState} onChange={handleSuccessClose} />
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/resetpassword" element={<ForgotPassword />} />
        <Route path="/books/*" element={<LandingComponent />} />
      </Routes>
    </>
  );
};

export default BookRoute;
