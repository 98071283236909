import React from "react";
// import { Grid, Typography } from "@material-ui/core";
// import { makeStyles } from "@mui/styles";
import styled from "@emotion/styled";
import { Grid, Typography } from "@mui/material";
// import Breadcrumbs from "@material-ui/core/Breadcrumbs";

const useStyles = styled((theme) => ({
  pageHeader: {
    fontWeight: "bold",
    paddingBottom: "0.3rem",
  },
}));
export const PageTittles = ({
  headingTitle = null,
  subtitleText = null,
  breadCrumb = null,
}) => {
  const classes = useStyles();
  return (
    <Grid direction="row">
      <Grid item xs={10}>
        {headingTitle ? (
          <Typography
            variant="h5"
            style={{ fontWeight: "bolder", paddingBottom: "0.3rem" }}
            className={classes.pageHeader}
          >
            {headingTitle}
          </Typography>
        ) : null}
        {subtitleText ? (
          <Typography variant="subtitle1" color="textSecondary">
            {subtitleText}
          </Typography>
        ) : null}
        {breadCrumb ? breadCrumb : null}
      </Grid>
      <hr />
    </Grid>
  );
};
