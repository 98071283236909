// External Dependencies
import React from "react";

// @MUI Import
import { Grid, Typography } from "@mui/material";

//Internal Dependencies
import { PageTittles } from "../../sharedComponents/pageTitle";
import JournalEntry from "./components/JournalEntry";
import JournalEntryView from "./components/JournalEntryView";
const AccountingTransaction = () => {
  return (
    <>
      <Grid item xs={12}>
        <PageTittles
          headingTitle="Transaction Entries"
          subtitleText={`Sub Title `}
        />
      </Grid>
      <JournalEntry />
      <JournalEntryView />
    </>
  );
};

export default AccountingTransaction;
