// External Dependencies
import React, { useEffect, useState, useContext } from "react";

// @MUI Import
import {
  Grid,
  Card,
  CardContent,
  Button,
  Typography,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControl,
  MenuItem,
  IconButton,
  Alert,
  Select,
  InputLabel,
  Switch,
  FormControlLabel,
} from "@mui/material";
import ClosedRoundedIcon from "@mui/icons-material/CloseRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { AlertContext } from "../../../../context/AlertContext";

// Formik
import { useFormik, FormikProvider, Form } from "formik";

// Yup
import * as Yup from "yup";

// Internal Import
import {
  LedgerServices,
  TransactionServices,
} from "../../../../authentication/services/amtrix.books.services";
import { LoadingButton } from "@mui/lab";

const ledgerServices = new LedgerServices();
const transactionServices = new TransactionServices();
const JournalEntry = () => {
  const [differenceAmount, setDifferenceAmount] = useState(0);
  const { changeAlertState } = useContext(AlertContext);
  const [accountTransactionType, setAccountTransactionType] = useState([]);
  const [salesPurchaseItemizedChange, setSalesPurchaseItemizedChange] =
    useState(false);
  const [paymentReceiptItemizedChange, setPaymentReceiptItemizedChange] =
    useState(false);

  const [isPurchSaleItemized, setIsPurchSaleItemized] = useState(false);
  const [isPayRecItemized, setIsPayRecItemized] = useState(false);

  useEffect(() => {
    const getAllTransactionsTypes = async () => {
      try {
        const response = await transactionServices.getAllTransactionsType();
        if (response.status === 200) {
          setAccountTransactionType(response.data.object);
        } else {
          changeAlertState({
            open: true,
            severity: "error",
            message: "Error fetching Account Transactions Types",
          });
        }
      } catch (error) {
        changeAlertState({
          open: true,
          message: error.message,
          severity: "error",
        });
      }
    };
    getAllTransactionsTypes();
  }, []);

  const onItemizedToggle = () => {
    if (
      values.transactionType === "sale" ||
      values.transactionType === "purchase"
    ) {
      setIsPurchSaleItemized(!isPurchSaleItemized);
    }

    if (
      values.transactionType === "payment" ||
      values.transactionType === "receipt"
    ) {
      setIsPayRecItemized(!isPayRecItemized);
    }
  };

  const transactionEntrySchema = Yup.object().shape({
    transactionNumber: Yup.string().required("Transaction Number is required"),
    transactionDate: Yup.date("Invalid Date").required(
      "Transaction Date is required"
    ),
    transactionType: Yup.string().required("Transaction Type is required"),
    journalEntry: Yup.array().of(
      Yup.object().shape({
        particulars: Yup.string()
          .typeError("Enter Valid Particulars")
          .required("Particulars is required"),
        debit: Yup.number()
          .typeError("Enter Valid Debit Amount")
          .min(0, "Debit Amount cannot be negative"),
        credit: Yup.number()
          .typeError("Enter Valid Debit Amount")
          .min(0, "Credit Amount cannot be negative"),
      })
    ),
  });

  const salesPurchaseItemizedSchema = Yup.object().shape({
    transactionNumber: Yup.string().required("Transaction Number is required"),
    transactionDate: Yup.date("Invalid Date").required(
      "Transaction Date is required"
    ),
    transactionType: Yup.string().required("Transaction Type is required"),
    billedDate: Yup.date("Invalid Date").required("Billed Date is required"),
    invoiceNo: Yup.string().required("Invoice Number is required"),
    supplierName: Yup.string().required("Supplier Name is required"),
    itemizedEntry: Yup.array().of(
      Yup.object().shape({
        product: Yup.string()
          .typeError("Enter Valid Product")
          .required("Product is required"),
        quantity: Yup.number()
          .typeError("Enter Valid Quantity")
          .min(0, "Quantity cannot be negative"),
        price: Yup.number()
          .typeError("Enter Valid Quantity")
          .min(0, "Price cannot be negative"),
        vat: Yup.number()
          .typeError("Enter Valid Quantity")
          .min(0, "Vat cannot be negative"),
        totalPrice: Yup.number()
          .typeError("Enter Valid Quantity")
          .min(0, "Total Price cannot be negative"),
      })
    ),
    subTotal: Yup.number().typeError("Enter Valid Quantity"),
  });

  const payReceiptItemizedSchema = Yup.object().shape({
    transactionNumber: Yup.string().required("Transaction Number is required"),
    transactionDate: Yup.date("Invalid Date").required(
      "Transaction Date is required"
    ),
    transactionType: Yup.string().required("Transaction Type is required"),
    customerName: Yup.string().required("Customer Name is required"),
    payRecItemizedEntry: Yup.array().of(
      Yup.object().shape({
        depositedTo: Yup.string()
          .typeError("Enter Valid Deposited To")
          .required("Deposited To is required"),
        reference: Yup.string()
          .typeError("Enter Valid Reference")
          .required("Reference is required"),
        receivedAmount: Yup.number()
          .typeError("Enter Valid Quantity")
          .min(0, "Received amount cannot be negative")
          .required("Received Amount is required"),
      })
    ),
    subTotal: Yup.number().typeError("Enter Valid Quantity"),
  });

  const onSubmitValidation = (values) => {
    // Check if the credit and debit amount is equal
    let debit = 0;
    let credit = 0;
    if (values.journalEntry.length > 0) {
      values.journalEntry.map((entry) => {
        if (entry.debit) {
          debit++;
        } else if (entry.credit) {
          credit++;
        }
      });
    }

    if (differenceAmount !== 0) {
      return {
        success: false,
        message: "Debit and Credit amount is not equal",
      };
    }

    // Validation that credit and debit should be 1:1, 1:N, N:1, N:N
    if (debit === 1 && credit > 1) {
      return { success: true, message: "Valid" };
    } else if (debit > 1 && credit === 1) {
      return { success: true, message: "Valid" };
    } else if (debit === credit) {
      return { success: true, message: "Valid" };
    } else {
      return { success: false, message: "Invalid" };
    }
  };

  const formik = useFormik({
    initialValues: {
      transactionNumber: "",
      transactionType: "",
      transactionDate: new Date().toISOString().slice(0, 10),
      billedDate: new Date().toISOString().slice(0, 10),
      invoiceNo: "",
      supplierName: "",
      subTotal: 0,
      journalEntry: [
        {
          id: 1,
          particulars: "",
          debit: 0,
          credit: 0,
        },
        {
          id: 2,
          particulars: "",
          debit: 0,
          credit: 0,
        },
      ],
      itemizedEntry: [
        {
          id: 1,
          product: "",
          quantity: 0,
          price: 0,
          vat: 0,
          totalPrice: 0,
        },
        {
          id: 2,
          product: "",
          quantity: 0,
          price: 0,
          vat: 0,
          totalPrice: 0,
        },
        {
          id: 3,
          product: "",
          quantity: 0,
          price: 0,
          vat: 0,
          totalPrice: 0,
        },
      ],
      payRecItemizedEntry: [
        {
          id: 1,
          depositedTo: "",
          reference: "",
          amount: 0,
        },
      ],
      payRecItemizedEntryTotal: 0,
    },

    validationSchema: isPurchSaleItemized
      ? salesPurchaseItemizedSchema
      : isPayRecItemized
      ? payReceiptItemizedSchema
      : transactionEntrySchema,
    onSubmit: async (values) => {
      const { success, message } = onSubmitValidation(values);
      if (!success) {
        changeAlertState({
          open: true,
          message: `${message}`,
          severity: "error",
        });
        return;
      }

      // Submit the form
      const ledgerEntry = {
        particularDtoList: [],
        transactionAmount: values.transactionNumber,
        transactionType: values.transactionType,
        transactionDate: values.transactionDate,
      };

      values.journalEntry.map((entry) => {
        ledgerEntry.particularDtoList.push({
          name: entry.particulars,
          amount: entry.debit || entry.credit,
          type: entry.debit ? "debit" : "credit",
        });
      });
      try {
        const response = await ledgerServices.addNewLedgerEntry(ledgerEntry);
        if (response.status === 201) {
          changeAlertState({
            open: true,
            message: `${response.data.message}`,
            severity: "success",
          });
          formik.resetForm();
        } else {
          changeAlertState({
            open: true,
            message: `${response.data.message}`,
            severity: "error",
          });
        }
      } catch (error) {
        console.log(error);
        changeAlertState({
          open: true,
          message: error.response.data.error,
          severity: "error",
        });
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } =
    formik;

  useEffect(() => {
    // Remove credit if debit is entered and vice versa
    for (let i = 0; i < values.journalEntry.length; i++) {
      if (values.journalEntry[i].debit > 0) {
        values.journalEntry[i].credit = 0;
      } else if (values.journalEntry[i].credit > 0) {
        values.journalEntry[i].debit = 0;
      }
    }
  }, [values.journalEntry]);

  useEffect(() => {
    if (
      values.transactionType !== "purchase" ||
      values.transactionType !== "sales"
    ) {
      setIsPurchSaleItemized(false);
    }
    if (
      values.transactionType !== "payment" ||
      values.transactionType !== "receipt"
    ) {
      setIsPayRecItemized(false);
    }
  }, [values.transactionType]);

  useEffect(() => {
    if (values.journalEntry.length > 1) {
      const debitSum = values.journalEntry.reduce((acc, curr) => {
        // Check if debit is number
        if (
          curr.debit !== undefined &&
          !isNaN(curr.debit) &&
          curr.debit !== "" &&
          curr.debit !== null
        ) {
          return acc + curr.debit;
        }
        return acc;
      }, 0);
      const creditSum = values.journalEntry.reduce((acc, curr) => {
        if (
          curr.credit !== undefined &&
          !isNaN(curr.credit) &&
          curr.credit !== "" &&
          curr.credit !== null
        ) {
          return acc + curr.credit;
        }
        return acc;
      }, 0);

      // absolute value of difference
      const difference = Math.abs(debitSum - creditSum);
      setDifferenceAmount(difference);
    }
  }, [formik.values.journalEntry]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setSalesPurchaseItemizedChange(!salesPurchaseItemizedChange);
    });

    return () => {
      clearTimeout(timeout);
    };
  }, [formik.values.itemizedEntry]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setPaymentReceiptItemizedChange(!paymentReceiptItemizedChange);
    });

    return () => {
      clearTimeout(timeout);
    };
  }, [formik.values.payRecItemizedEntry]);

  useEffect(() => {
    if (values.journalEntry.length > 0) {
      let subTotal = 0;
      values.itemizedEntry.map((entry) => {
        if (entry.price && entry.quantity) {
          let vat = entry.price * entry.quantity * 0.13;
          let totalPrice = entry.price * entry.quantity + vat;
          entry.vat = vat;
          entry.totalPrice = totalPrice;
          subTotal += totalPrice;
        }
      });

      // formik.setFieldValue("itemizedEntry", values.itemizedEntry);
      formik.setFieldValue("subTotal", subTotal);
    }
  }, [salesPurchaseItemizedChange]);

  useEffect(() => {
    if (values.payRecItemizedEntry.length > 0) {
      let subTotal = 0;
      values.payRecItemizedEntry.map((entry) => {
        if (entry.receivedAmount) {
          subTotal += entry.receivedAmount;
        }
      });

      formik.setFieldValue("payRecItemizedEntryTotal", subTotal);
    }
  }, [paymentReceiptItemizedChange]);

  const addNewRowForJournal = () => {
    formik.setFieldValue("journalEntry", [
      ...formik.values.journalEntry,
      {
        id:
          formik.values.journalEntry[formik.values.journalEntry.length - 1].id +
          1,
        particulars: "",
        debit: 0,
        credit: 0,
      },
    ]);
  };

  const addNewRowForItemizedEntry = () => {
    formik.setFieldValue("itemizedEntry", [
      ...formik.values.itemizedEntry,
      {
        id:
          formik.values.itemizedEntry[formik.values.itemizedEntry.length - 1]
            .id + 1,
        product: "",
        quantity: 0,
        price: 0,
        vat: 0,
        totalPrice: 0,
      },
    ]);
  };

  const addNewRowForPayReceItemizedEntry = () => {
    formik.setFieldValue("payRecItemizedEntry", [
      ...formik.values.payRecItemizedEntry,
      {
        id:
          formik.values.payRecItemizedEntry[
            formik.values.payRecItemizedEntry.length - 1
          ].id + 1,
        depositedTo: "",
        reference: "",
        receivedAmount: 0,
      },
    ]);
  };

  const removeRowForJournalParticulars = (id) => {
    const newJournalEntry = formik.values.journalEntry.filter(
      (journal, i) => journal.id !== id
    );
    formik.setFieldValue("journalEntry", newJournalEntry);
  };

  const removeRowForItemizedJournal = (id) => {
    console.log(id);
    const newItemizedJournalEntry = formik.values.itemizedEntry.filter(
      (itemizedJournal, i) => itemizedJournal.id !== id
    );
    formik.setFieldValue("itemizedEntry", newItemizedJournalEntry);
  };

  const removeRowForPayReceItemizedEntry = (id) => {
    const newPayReceItemizedEntry = formik.values.payRecItemizedEntry.filter(
      (payReceItemized, i) => payReceItemized.id !== id
    );
    formik.setFieldValue("payRecItemizedEntry", newPayReceItemizedEntry);
  };

  return (
    <Grid direction="row" item container xs={12}>
      <Card elevation={0} variant="outlined">
        <FormikProvider
          value={formik}
          validateOnChange={false}
          validateOnBlur={false}
        >
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <CardContent>
              <Grid item container spacing={2}>
                <Grid item container sm={12} md={12} xs={12}>
                  <Grid item sm={12} md={12} xs={12}>
                    <Typography>
                      <b>Journal Entry</b>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item container xs={12} spacing={2}>
                  <Grid item sm={6} md={3} xs={12}>
                    <TextField
                      id="txnNumber"
                      label="Txn No:"
                      variant="outlined"
                      size="small"
                      {...getFieldProps("transactionNumber")}
                      error={Boolean(
                        touched.transactionNumber && errors.transactionNumber
                      )}
                      helperText={
                        touched.transactionNumber && errors.transactionNumber
                      }
                    />
                  </Grid>
                  <Grid item sm={6} md={3} xs={12}>
                    <TextField
                      id="txnDate"
                      type="date"
                      label="Txn Date:"
                      variant="outlined"
                      size="small"
                      {...getFieldProps("transactionDate")}
                      error={Boolean(
                        touched.transactionDate && errors.transactionDate
                      )}
                      helperText={
                        touched.transactionDate && errors.transactionDate
                      }
                    />
                  </Grid>
                  <Grid item sm={6} md={3} xs={12}>
                    <FormControl fullWidth size="small">
                      <TextField
                        id="txnType"
                        label="Txn Type:"
                        variant="outlined"
                        {...getFieldProps("transactionType")}
                        error={Boolean(
                          touched.transactionType && errors.transactionType
                        )}
                        helperText={
                          touched.transactionType && errors.transactionType
                        }
                        select
                      >
                        {accountTransactionType.length > 0 &&
                          accountTransactionType.map((type) => {
                            return [
                              <MenuItem value={type.name}>
                                {type.name}
                              </MenuItem>,
                            ];
                          })}
                      </TextField>
                    </FormControl>
                  </Grid>
                  {(values.transactionType.toLowerCase() === "purchase" ||
                    values.transactionType.toLowerCase() === "sale" ||
                    values.transactionType.toLowerCase() === "payment" ||
                    values.transactionType.toLowerCase() === "receipt") && (
                    <Grid item sm={6} md={3} xs={12}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={
                              values.transactionType === "purchase" ||
                              values.transactionType === "sale"
                                ? isPurchSaleItemized
                                : isPayRecItemized
                            }
                            onChange={onItemizedToggle}
                          />
                        }
                        label="Itemized"
                      />
                    </Grid>
                  )}
                </Grid>

                {isPayRecItemized && (
                  <Grid item container xs={12} spacing={2}>
                    <Grid item sm={6} md={3} xs={12}>
                      <FormControl fullWidth size="small">
                        <TextField
                          id="customerName"
                          label="Customer Name"
                          variant="outlined"
                          {...getFieldProps("customerName")}
                          error={Boolean(
                            touched.customerName && errors.customerName
                          )}
                          helperText={
                            touched.customerName && errors.customerName
                          }
                          select
                        >
                          <MenuItem value="test">Test User</MenuItem>
                          <MenuItem value="test1">Test User 2</MenuItem>
                        </TextField>
                      </FormControl>
                    </Grid>
                  </Grid>
                )}

                {isPurchSaleItemized && (
                  <Grid item container xs={12} spacing={2}>
                    <Grid item sm={6} md={4} xs={12}>
                      <TextField
                        id="billedDate"
                        type="date"
                        label="Billed Date:"
                        variant="outlined"
                        size="small"
                        {...getFieldProps("billedDate")}
                        error={Boolean(touched.billedDate && errors.billedDate)}
                        helperText={touched.billedDate && errors.billedDate}
                      />
                    </Grid>
                    <Grid item sm={6} md={4} xs={12}>
                      <TextField
                        id="invoiceNumber"
                        type="text"
                        label="Invoice Number"
                        variant="outlined"
                        size="small"
                        {...getFieldProps("invoiceNumber")}
                        error={Boolean(
                          touched.invoiceNumber && errors.invoiceNumber
                        )}
                        helperText={
                          touched.invoiceNumber && errors.invoiceNumber
                        }
                      />
                    </Grid>
                    <Grid item sm={6} md={4} xs={12}>
                      <TextField
                        id="supplierName"
                        type="text"
                        label="Supplier Name"
                        variant="outlined"
                        size="small"
                        {...getFieldProps("supplierName")}
                        error={Boolean(
                          touched.supplierName && errors.supplierName
                        )}
                        helperText={touched.supplierName && errors.supplierName}
                      />
                    </Grid>
                  </Grid>
                )}

                {!isPurchSaleItemized && !isPayRecItemized && (
                  <JournalParticularsEntry
                    removeRowForJournalParticulars={
                      removeRowForJournalParticulars
                    }
                    formik={formik}
                    addNewRowForJournal={addNewRowForJournal}
                    differenceAmount={differenceAmount}
                  />
                )}

                {isPurchSaleItemized && (
                  <ItemizedJournalParticularsEntry
                    formik={formik}
                    addNewRowForItemizedEntry={addNewRowForItemizedEntry}
                    removeRowForItemizedJournal={removeRowForItemizedJournal}
                  />
                )}

                {isPayRecItemized && (
                  <PaymentReceiptItemizedEntry
                    formik={formik}
                    addNewRowForPayReceItemizedEntry={
                      addNewRowForPayReceItemizedEntry
                    }
                    removeRowForPayReceItemizedEntry={
                      removeRowForPayReceItemizedEntry
                    }
                  />
                )}

                {/* <Divider variant="middle" /> */}
                <Grid item sm={12} md={12} xs={12}>
                  {differenceAmount !== 0 && (
                    <Alert xs={3} severity="warning">
                      Invalid Difference Amount
                    </Alert>
                  )}
                </Grid>

                <Grid item container xs={12} spacing={2}>
                  <Grid item md={isPurchSaleItemized ? 6 : 9} xs={12}>
                    <TextField
                      variant="outlined"
                      multiline
                      rows={isPurchSaleItemized ? 6 : 4}
                      label="Description"
                      {...getFieldProps("transactionDescription")}
                      error={Boolean(
                        touched.transactionDescription &&
                          errors.transactionDescription
                      )}
                      helperText={
                        touched.transactionDescription &&
                        errors.transactionDescription
                      }
                      fullWidth
                    />
                  </Grid>
                  {isPurchSaleItemized && (
                    <Grid item container md={6} xs={12} spacing={2}>
                      <Grid item container xs={12} spacing={2}>
                        <Grid
                          item
                          xs={4}
                          container
                          justifyContent="flex-end"
                          alignContent="center"
                        >
                          <Typography>Sub Total:</Typography>
                        </Grid>
                        <Grid item xs={8} container alignContent="center">
                          <TextField
                            variant="outlined"
                            type="number"
                            label="Sub Total"
                            {...getFieldProps("subTotal")}
                            error={Boolean(touched.subTotal && errors.subTotal)}
                            helperText={touched.subTotal && errors.subTotal}
                            fullWidth
                            disabled
                          />
                        </Grid>
                      </Grid>
                      <Grid item container xs={12} spacing={2}>
                        <Grid
                          item
                          xs={4}
                          container
                          justifyContent="flex-end"
                          alignContent="center"
                        >
                          <Typography>Discount:</Typography>
                        </Grid>
                        <Grid item xs={3} container alignContent="center">
                          <TextField label="%" />
                        </Grid>
                        <Grid item xs={5} container alignContent="center">
                          <TextField type="number" label="Amount" />
                        </Grid>
                      </Grid>
                      <Grid item container xs={12} spacing={2}>
                        <Grid
                          item
                          xs={4}
                          container
                          justifyContent="flex-end"
                          alignContent="center"
                        >
                          <Typography>Taxable Amt:</Typography>
                        </Grid>
                        <Grid item xs={8} container alignContent="center">
                          <TextField />
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>

                {isPurchSaleItemized && (
                  <Grid item container xs={12} alignItems="center" spacing={2}>
                    <Grid item container md={6} xs={12}></Grid>
                    <Grid item container md={6} xs={12} spacing={2}>
                      <Grid
                        item
                        md={4}
                        xs={12}
                        container
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        <Typography variant="h6">Total:</Typography>
                      </Grid>
                      <Grid
                        item
                        container
                        md={8}
                        xs={12}
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        <Typography variant="h6">1,34,453</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                <Grid
                  item
                  container
                  xs={12}
                  // md={2}
                  justifyContent="flex-end"
                  direction="row"
                  spacing={2}
                >
                  <Grid item>
                    <Button
                      variant="outlined"
                      onClick={(e) => {
                        e.preventDefault();
                        formik.resetForm();
                      }}
                    >
                      Clear
                    </Button>
                  </Grid>
                  <Grid item>
                    <LoadingButton
                      type="submit"
                      loading={""}
                      variant="contained"
                    >
                      Save
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Form>
        </FormikProvider>
      </Card>

      {/* <Card elevation={0} variant="outlined">
        <CardContent>
          <Grid item container spacing={2}>
            <Grid item container direction="row" xs={12}>
              <Grid item sm={12} md={12} xs={12}>
                <Typography>
                  <b>Journal Entry</b>
                </Typography>
              </Grid>
            </Grid>
            <Grid item container direction="row" xs={12} spacing={2}>
              <Grid item sm={6} md={3} xs={12}>
                <TextField
                  id="txnNumber"
                  label="Txn No:"
                  variant="outlined"
                  size="small"
                  {...getFieldProps("transactionNumber")}
                  error={Boolean(
                    touched.transactionNumber && errors.transactionNumber
                  )}
                  helperText={
                    touched.transactionNumber && errors.transactionNumber
                  }
                />
              </Grid>
              <Grid item sm={6} md={3} xs={12}>
                <TextField
                  id="txnDate"
                  type="date"
                  label="Txn Date:"
                  variant="outlined"
                  size="small"
                  {...getFieldProps("transactionDate")}
                  error={Boolean(
                    touched.transactionDate && errors.transactionDate
                  )}
                  helperText={touched.transactionDate && errors.transactionDate}
                />
              </Grid>
              <Grid item sm={6} md={4} xs={12}>
                <FormControl fullWidth size="small">
                  <InputLabel id="purchaseSales">Purchase/Sales:</InputLabel>
                  <Select
                    labelId="demo-select-small"
                    id="purchaseSales"
                    label="Purchase/Sales:"
                  >
                    <MenuItem value={10}>Debit</MenuItem>
                    <MenuItem value={20}>Credit</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={6} md={2} xs={12}>
                <Button variant="text">Itemize</Button>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth size="small">
                <InputLabel id="purchaseSales">Customer Name:</InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="purchaseSales"
                  label="Purchase/Sales:"
                >
                  <MenuItem value={10}>Debit</MenuItem>
                  <MenuItem value={20}>Credit</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <TableContainer>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Deposited To</TableCell>
                      <TableCell>Reference</TableCell>
                      <TableCell>Received Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <TextField fullWidth variant="outlined" size="small" />
                      </TableCell>
                      <TableCell>
                        <TextField
                          variant="outlined"
                          type="text"
                          size="small"
                        />
                      </TableCell>
                      <TableCell
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-around"
                        }}
                      >
                        <TextField
                          variant="outlined"
                          size="small"
                          type="text"
                        />
                        <IconButton aria-label="remove">
                          <ClosedRoundedIcon></ClosedRoundedIcon>
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            <Grid item container xs={12}>
              <Grid item xs={9}>
                <Button
                  variant="text"
                  onClick={e => {
                    e.preventDefault();
                    addNewRowForJournal();
                  }}
                  startIcon={<AddRoundedIcon />}
                >
                  Add Another Line
                </Button>
              </Grid>

              <Grid item xs={3}>
                Total: 12,34,899
              </Grid>
            </Grid>

            <Grid item sm={12} md={12} xs={12}>
              <TableContainer>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Txn Date</TableCell>
                      <TableCell>Invoice Details</TableCell>
                      <TableCell>Amount</TableCell>
                      <TableCell>Balance</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            <Grid item container xs={12} spacing={2}>
              <Grid item md={9} xs={8}>
                <TextField
                  variant="outlined"
                  multiline
                  rows={4}
                  label="Description"
                  {...getFieldProps("transactionDescription")}
                  error={Boolean(
                    touched.transactionDescription &&
                      errors.transactionDescription
                  )}
                  helperText={
                    touched.transactionDescription &&
                    errors.transactionDescription
                  }
                  fullWidth
                />
              </Grid>
              <Grid
                item
                container
                direction="row"
                justifyContent="flex-end"
                md={3}
                xs={4}
                spacing={1}
                sx={{ alignItems: "flex-end" }}
              >
                <Grid item>
                  <Button
                    variant="outlined"
                    onClick={e => {
                      e.preventDefault();
                      formik.resetForm();
                    }}
                  >
                    Clear
                  </Button>
                </Grid>
                <Grid item>
                  <LoadingButton
                    type="submit"
                    loading={isSubmitting}
                    variant="contained"
                  >
                    Save
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card> */}
    </Grid>
  );
};

const JournalParticularsEntry = ({
  formik,
  removeRowForJournalParticulars,
  addNewRowForJournal,
  differenceAmount,
}) => {
  const { errors, touched, values, getFieldProps } = formik;
  return (
    <>
      <Grid item xs={12}>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Particulars</TableCell>
                <TableCell>Debit</TableCell>
                <TableCell>Credit</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {values.journalEntry.map((journalRow, index) => {
                return (
                  <TableRow key={journalRow.id}>
                    <TableCell>
                      <TextField
                        fullWidth
                        id="particulars"
                        // label="Particulars"
                        variant="outlined"
                        size="small"
                        {...getFieldProps(`journalEntry.${index}.particulars`)}
                        error={Boolean(
                          touched.journalEntry &&
                            touched.journalEntry[index] &&
                            touched.journalEntry[index].particulars &&
                            errors.journalEntry &&
                            errors.journalEntry[index] &&
                            errors.journalEntry[index].particulars
                        )}
                        helperText={
                          touched.journalEntry &&
                          touched.journalEntry[index] &&
                          touched.journalEntry[index].particulars &&
                          errors.journalEntry &&
                          errors.journalEntry[index] &&
                          errors.journalEntry[index].particulars
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        id="debit"
                        // label="Debit"
                        variant="outlined"
                        type="number"
                        size="small"
                        {...getFieldProps(`journalEntry.${index}.debit`)}
                        error={Boolean(
                          touched.journalEntry &&
                            touched.journalEntry[index] &&
                            touched.journalEntry[index].debit &&
                            errors.journalEntry &&
                            errors.journalEntry[index] &&
                            errors.journalEntry[index].debit
                        )}
                        helperText={
                          touched.journalEntry &&
                          touched.journalEntry[index] &&
                          touched.journalEntry[index].debit &&
                          errors.journalEntry &&
                          errors.journalEntry[index] &&
                          errors.journalEntry[index].debit
                        }
                      />
                    </TableCell>
                    <TableCell
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-around",
                      }}
                    >
                      <TextField
                        id="credit"
                        // label="Credit"
                        variant="outlined"
                        size="small"
                        type="number"
                        {...getFieldProps(`journalEntry.${index}.credit`)}
                        error={Boolean(
                          touched.journalEntry &&
                            touched.journalEntry[index] &&
                            touched.journalEntry[index].credit &&
                            errors.journalEntry &&
                            errors.journalEntry[index] &&
                            errors.journalEntry[index].credit
                        )}
                        helperText={
                          touched.journalEntry &&
                          touched.journalEntry[index] &&
                          touched.journalEntry[index].credit &&
                          errors.journalEntry &&
                          errors.journalEntry[index] &&
                          errors.journalEntry[index].credit
                        }
                      />
                      <IconButton
                        style={{ opacity: index < 2 ? "0.0" : "1.0" }}
                        aria-label="remove"
                        onClick={() => {
                          index > 1 &&
                            removeRowForJournalParticulars(journalRow.id);
                        }}
                      >
                        <ClosedRoundedIcon></ClosedRoundedIcon>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item container xs={12}>
        <Grid item xs={8}>
          <Button
            variant="text"
            onClick={(e) => {
              e.preventDefault();
              addNewRowForJournal();
            }}
            startIcon={<AddRoundedIcon />}
          >
            Add Another Line
          </Button>
        </Grid>

        <Grid item xs={3}>
          Difference Amount: <strong>{differenceAmount}</strong>
        </Grid>
      </Grid>
    </>
  );
};

const ItemizedJournalParticularsEntry = ({
  formik,
  addNewRowForItemizedEntry,
  removeRowForItemizedJournal,
}) => {
  const { errors, touched, values, getFieldProps } = formik;
  return (
    <>
      <Grid item xs={12}>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Products</TableCell>
                <TableCell>Qty</TableCell>
                <TableCell>Price</TableCell>
                <TableCell>VAT(13%)</TableCell>
                <TableCell>Total Price</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {values.itemizedEntry.map((journalRow, index) => {
                return (
                  <TableRow key={journalRow.id}>
                    <TableCell sx={{ width: 500 }}>
                      <TextField
                        fullWidth
                        id="product"
                        // label="Product"
                        variant="outlined"
                        size="small"
                        {...getFieldProps(`itemizedEntry.${index}.product`)}
                        error={Boolean(
                          touched.itemizedEntry &&
                            touched.itemizedEntry[index] &&
                            touched.itemizedEntry[index].product &&
                            errors.itemizedEntry &&
                            errors.itemizedEntry[index] &&
                            errors.itemizedEntry[index].product
                        )}
                        helperText={
                          touched.itemizedEntry &&
                          touched.itemizedEntry[index] &&
                          touched.itemizedEntry[index].product &&
                          errors.itemizedEntry &&
                          errors.itemizedEntry[index] &&
                          errors.itemizedEntry[index].product
                        }
                      />
                    </TableCell>
                    <TableCell sx={{ width: 100 }}>
                      <TextField
                        fullWidth
                        id="quantity"
                        // label="Qty"
                        variant="outlined"
                        size="small"
                        {...getFieldProps(`itemizedEntry.${index}.quantity`)}
                        error={Boolean(
                          touched.itemizedEntry &&
                            touched.itemizedEntry[index] &&
                            touched.itemizedEntry[index].quantity &&
                            errors.itemizedEntry &&
                            errors.itemizedEntry[index] &&
                            errors.itemizedEntry[index].quantity
                        )}
                        helperText={
                          touched.itemizedEntry &&
                          touched.itemizedEntry[index] &&
                          touched.itemizedEntry[index].quantity &&
                          errors.itemizedEntry &&
                          errors.itemizedEntry[index] &&
                          errors.itemizedEntry[index].quantity
                        }
                      />
                    </TableCell>
                    <TableCell sx={{ width: 150 }}>
                      <TextField
                        fullWidth
                        id="price"
                        // label="Price"
                        variant="outlined"
                        size="small"
                        {...getFieldProps(`itemizedEntry.${index}.price`)}
                        error={Boolean(
                          touched.itemizedEntry &&
                            touched.itemizedEntry[index] &&
                            touched.itemizedEntry[index].price &&
                            errors.itemizedEntry &&
                            errors.itemizedEntry[index] &&
                            errors.itemizedEntry[index].price
                        )}
                        helperText={
                          touched.itemizedEntry &&
                          touched.itemizedEntry[index] &&
                          touched.itemizedEntry[index].price &&
                          errors.itemizedEntry &&
                          errors.itemizedEntry[index] &&
                          errors.itemizedEntry[index].price
                        }
                      />
                    </TableCell>
                    <TableCell sx={{ width: 150 }}>
                      <TextField
                        fullWidth
                        id="vat"
                        // label="VAT"
                        variant="outlined"
                        size="small"
                        {...getFieldProps(`itemizedEntry.${index}.vat`)}
                        error={Boolean(
                          touched.itemizedEntry &&
                            touched.itemizedEntry[index] &&
                            touched.itemizedEntry[index].vat &&
                            errors.itemizedEntry &&
                            errors.itemizedEntry[index] &&
                            errors.itemizedEntry[index].vat
                        )}
                        helperText={
                          touched.itemizedEntry &&
                          touched.itemizedEntry[index] &&
                          touched.itemizedEntry[index].vat &&
                          errors.itemizedEntry &&
                          errors.itemizedEntry[index] &&
                          errors.itemizedEntry[index].vat
                        }
                        disabled
                      />
                    </TableCell>
                    <TableCell sx={{ width: 300 }}>
                      <TextField
                        fullWidth
                        id="totalPrice"
                        // label="Total Price"
                        variant="outlined"
                        size="small"
                        {...getFieldProps(`itemizedEntry.${index}.totalPrice`)}
                        error={Boolean(
                          touched.itemizedEntry &&
                            touched.itemizedEntry[index] &&
                            touched.itemizedEntry[index].totalPrice &&
                            errors.itemizedEntry &&
                            errors.itemizedEntry[index] &&
                            errors.itemizedEntry[index].totalPrice
                        )}
                        helperText={
                          touched.itemizedEntry &&
                          touched.itemizedEntry[index] &&
                          touched.itemizedEntry[index].totalPrice &&
                          errors.itemizedEntry &&
                          errors.itemizedEntry[index] &&
                          errors.itemizedEntry[index].totalPrice
                        }
                        disabled
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton
                        style={{ opacity: index < 1 ? "0.0" : "1.0" }}
                        aria-label="remove"
                        onClick={() => {
                          index > 0 &&
                            removeRowForItemizedJournal(journalRow.id);
                        }}
                      >
                        <ClosedRoundedIcon></ClosedRoundedIcon>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item container xs={12}>
        <Grid item xs={9}>
          <Button
            variant="text"
            onClick={(e) => {
              e.preventDefault();
              addNewRowForItemizedEntry();
            }}
            startIcon={<AddRoundedIcon />}
          >
            Add Another Line
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

const PaymentReceiptItemizedEntry = ({
  formik,
  addNewRowForPayReceItemizedEntry,
  removeRowForPayReceItemizedEntry,
}) => {
  const { errors, touched, values, getFieldProps } = formik;
  return (
    <>
      <Grid item xs={12}>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Deposited To</TableCell>
                <TableCell>Reference</TableCell>
                <TableCell>Received Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {values.payRecItemizedEntry.map((row, index) => {
                return (
                  <TableRow>
                    <TableCell>
                      <TextField
                        fullWidth
                        id="DepositedTo"
                        variant="outlined"
                        size="small"
                        {...getFieldProps(
                          `payRecItemizedEntry.${index}.depositedTo`
                        )}
                        error={Boolean(
                          touched.payRecItemizedEntry &&
                            touched.payRecItemizedEntry[index] &&
                            touched.payRecItemizedEntry[index].depositedTo &&
                            errors.payRecItemizedEntry &&
                            errors.payRecItemizedEntry[index] &&
                            errors.payRecItemizedEntry[index].depositedTo
                        )}
                        helperText={
                          touched.payRecItemizedEntry &&
                          touched.payRecItemizedEntry[index] &&
                          touched.payRecItemizedEntry[index].depositedTo &&
                          errors.payRecItemizedEntry &&
                          errors.payRecItemizedEntry[index] &&
                          errors.payRecItemizedEntry[index].depositedTo
                        }
                      />
                      {/* <TextField fullWidth variant="outlined" size="small" /> */}
                    </TableCell>
                    <TableCell>
                      <TextField
                        fullWidth
                        id="reference"
                        variant="outlined"
                        size="small"
                        {...getFieldProps(
                          `payRecItemizedEntry.${index}.reference`
                        )}
                        error={Boolean(
                          touched.payRecItemizedEntry &&
                            touched.payRecItemizedEntry[index] &&
                            touched.payRecItemizedEntry[index].reference &&
                            errors.payRecItemizedEntry &&
                            errors.payRecItemizedEntry[index] &&
                            errors.payRecItemizedEntry[index].reference
                        )}
                        helperText={
                          touched.payRecItemizedEntry &&
                          touched.payRecItemizedEntry[index] &&
                          touched.payRecItemizedEntry[index].reference &&
                          errors.payRecItemizedEntry &&
                          errors.payRecItemizedEntry[index] &&
                          errors.payRecItemizedEntry[index].reference
                        }
                      />
                    </TableCell>
                    <TableCell
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-around",
                      }}
                    >
                      <TextField
                        fullWidth
                        id="receivedAmount"
                        variant="outlined"
                        type="number"
                        size="small"
                        {...getFieldProps(
                          `payRecItemizedEntry.${index}.receivedAmount`
                        )}
                        error={Boolean(
                          touched.payRecItemizedEntry &&
                            touched.payRecItemizedEntry[index] &&
                            touched.payRecItemizedEntry[index].receivedAmount &&
                            errors.payRecItemizedEntry &&
                            errors.payRecItemizedEntry[index] &&
                            errors.payRecItemizedEntry[index].receivedAmount
                        )}
                        helperText={
                          touched.payRecItemizedEntry &&
                          touched.payRecItemizedEntry[index] &&
                          touched.payRecItemizedEntry[index].receivedAmount &&
                          errors.payRecItemizedEntry &&
                          errors.payRecItemizedEntry[index] &&
                          errors.payRecItemizedEntry[index].receivedAmount
                        }
                      />
                      <IconButton
                        style={{ opacity: index < 1 ? "0.0" : "1.0" }}
                        aria-label="remove"
                        onClick={() => {
                          index > 0 && removeRowForPayReceItemizedEntry(row.id);
                        }}
                      >
                        <ClosedRoundedIcon></ClosedRoundedIcon>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item container xs={12}>
        <Grid item xs={9}>
          <Button
            variant="text"
            onClick={(e) => {
              e.preventDefault();
              addNewRowForPayReceItemizedEntry();
            }}
            startIcon={<AddRoundedIcon />}
          >
            Add Another Line
          </Button>
        </Grid>
        <Grid item xs={3}>
          Total: <strong>{values.payRecItemizedEntryTotal}</strong>
        </Grid>
      </Grid>
      <Grid item sm={12} md={12} xs={12}>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Txn Date</TableCell>
                <TableCell>Invoice Details</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Balance</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </>
  );
};

export default JournalEntry;
