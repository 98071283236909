import React from "react";
import { Grid, Card, CardContent, Typography } from "@mui/material/";
import DynamicTable from "../../../sharedComponents/table/DynamicTable";
import moment from "moment";

const LedgerView = ({ ledgerList, searchData }) => {
  const columns = [
    { field: "accountHead", title: "Particular" },
    { field: "transactionId", title: "Txn No." },
    { field: "amount", title: "Debit", money: "debit" },
    { field: "amount", title: "Credit", money: "credit" },
    { field: "transactionDate", title: "Date", type: "date" }
  ];

  return (
    <Grid item xs={12}>
    <Card elevation={0} variant='outlined'>
      <CardContent>
        <Grid item container spacing={2}>
          <Grid item sm={12} md={12} xs={12}>
            <Typography>
              <b>Account Summary(Ledger)</b>
            </Typography>
            <Typography>Demo Pharmacy Limited</Typography>
            {searchData.startDate && searchData.endDate && (
              <Typography>
                From{" "}
                {moment(new Date(searchData.startDate).toUTCString()).format(
                  "dddd, MMMM Do YYYY, h:mm:ss a"
                )}{" "}
                <b>to</b>{" "}
                {moment(new Date(searchData.endDate).toUTCString()).format(
                  "dddd, MMMM Do YYYY, h:mm:ss a"
                )}
              </Typography>
            )}
          </Grid>
          {/* <Grid item sm={12} md={12} xs={12}> */}
          <DynamicTable data={ledgerList} column={columns} tableType='journalView' />
          {/* </Grid> */}
        </Grid>
      </CardContent>
    </Card>
    </Grid>
  );
};

export default LedgerView;
