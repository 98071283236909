import React, { useState, useEffect } from 'react';
import { Snackbar, Alert } from '@mui/material';

export const AlertNotification = props => {
  const [alertState, setAlertState] = useState({});

  useEffect(() => {
    if (props.openState != null) {
      setAlertState(props.openState);
    }
  });
  const handleSuccessClose = () => {
    props.onChange(false);
  };

  return (
    <Snackbar
      style={{ width: '100%' }}
      open={alertState.open}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={5000}
      onClose={handleSuccessClose}
    >
      <Alert
        elevation={6}
        variant='filled'
        onClose={handleSuccessClose}
        severity={alertState.severity}
      >
        {alertState.message}
      </Alert>
    </Snackbar>
  );
};

AlertNotification.defaultProps = {
  isAnnouncement: false
};
